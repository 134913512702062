import { useEffect, useMemo, useState } from 'react'
import { Input, Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import styles from './Support.module.scss'
import Loading from '../../../components/Loading/Loading'
import SearchSvg from '../../../images/search.svg'
import { IPatients } from '../../../redux/admin/patient/interface'
import {
  getFilteredPatientSupportAsync,
  resetFilteredPatients,
  resetnoPatientFoundTag,
  setSearchValue,
} from '../../../redux/admin/support'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { StatusEnum } from '../../../redux/type'

export default function Support() {
  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <div className={styles.supportContainer}>
          <SupportSearch></SupportSearch>
        </div>
      </Content>
    </Layout>
  )
}

export function SupportSearch() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const filteredPatients = useAppSelector((state) => state.admin.support.filteredPatientSupport)
  const filteredPatientsStatus = useAppSelector((state) => state.admin.support.filteredPatientSupportStatus)
  const noPatientFound = useAppSelector((state) => state.admin.support.noPatientFoundTag)
  const searchValue = useAppSelector((state) => state.admin.support.searchValue)
  const [preventInitialCall, setPreventInitialCall] = useState(true)

  useEffect(() => {
    // const isSingleSpace = /^ $/.test(searchValue[currentLength - 1])
    const handleSearch = () => {
      const currentLength = searchValue?.trim().length || 0
      // Case when the length is 1 or more
      if (currentLength >= 1 && !preventInitialCall) {
        dispatch(getFilteredPatientSupportAsync({ searchText: searchValue.trim() }))
      }
    }

    const timeoutId = setTimeout(handleSearch, 850)
    return () => clearTimeout(timeoutId)
  }, [searchValue])

  useEffect(() => {
    if (searchValue.length === 0 && filteredPatients.length !== 0) {
      dispatch(resetFilteredPatients())
    }
  }, [searchValue, filteredPatients])

  return (
    <div>
      <div className={styles.headerContainer}>
        <Input
          className={styles.searchBar}
          placeholder="Search..."
          prefix={<img style={{ marginRight: '6px' }} alt="Search icon" src={SearchSvg} />}
          value={searchValue}
          onChange={(e: any) => {
            dispatch(setSearchValue(e.target.value))
            if (e.target.value.length === 0 && filteredPatients.length !== 0) {
              dispatch(resetFilteredPatients())
            }
            if (preventInitialCall) {
              setPreventInitialCall(false)
            }
            if (noPatientFound) {
              dispatch(resetnoPatientFoundTag())
            }
          }}
          autoFocus
        ></Input>
      </div>
      {filteredPatientsStatus !== StatusEnum.Pending && (
        <div className={styles.appointmentCards}>
          <div className={styles.cardList}>
            {filteredPatients?.map((a: IPatients) => (
              <AppointmentCardItems
                key={a.id}
                appointments={a}
                onClick={() => {
                  navigate('/support/patients/' + a.user.id)
                }}
              />
            ))}
            {noPatientFound && <div className={styles.noAppointments}>No patient found</div>}
          </div>
        </div>
      )}
      {filteredPatientsStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}
    </div>
  )
}

interface AppointmentCardItem {
  appointments: IPatients
  onClick?: () => void
  onCancel?: () => void
}
const AppointmentCardItems = ({ appointments, onClick }: AppointmentCardItem) => {
  const initialLetters = useMemo(() => {
    let firstName = ''
    let lastName = ''
    if (appointments?.user?.firstname) {
      firstName = appointments?.user?.firstname[0]?.toUpperCase()
    } else if (appointments?.user?.lastname) {
      lastName = appointments?.user?.lastname[0]?.toUpperCase()
    }
    return firstName + lastName
  }, [appointments])

  let appointmentDateTime = useMemo(() => {
    if (appointments.latestVisit) {
      const visit = new Date(appointments.latestVisit)
      return format(visit, 'MMM dd, yyyy, h:mm a')
    } else {
      return '-'
    }
  }, [appointments])

  return (
    <div className={styles.profileContainer} onClick={onClick}>
      <div className={styles.profileLeftDiv}>
        {appointments?.user?.profilePic && (
          <img alt="ProfileImg" className={styles.profileImg} src={appointments?.user?.profilePic}></img>
        )}
        {!appointments?.user?.profilePic && <span className={styles.profileImgTxt}>{initialLetters}</span>}

        <span className={styles.profileName}>
          {(appointments.user?.firstname || '') + ' ' + (appointments.user?.lastname || '')}
        </span>
      </div>
      <div className={styles.profileLeftDiv} style={{ minWidth: 200 }}>
        {<span className={styles.phone}>{appointments.user.phone}</span>}
      </div>
      <div className={styles.profileLeftDiv} style={{ minWidth: 200 }}>
        {<span>{appointmentDateTime}</span>}
      </div>
    </div>
  )
}
