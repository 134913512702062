import { useCallback } from 'react'
import { FieldTimeOutlined, ScheduleOutlined, UserOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { Helmet } from 'react-helmet'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import style from './Routes.module.scss'
import { Button } from '../components/Button/Button'
import { useAuth } from '../hooks/useAuth'
import appointmentsSvg from '../images/appointmentsIcon.svg'
import ChiropractorSvg from '../images/chiropractor.svg'
import ClinicSvg from '../images/clinic.svg'
import DoctorsSvg from '../images/doctors.svg'
import growthSvg from '../images/growth.svg'
import HomeSvg from '../images/home.svg'
import Logo from '../images/kLogo.svg'
import LogoutSvg from '../images/logout.svg'
import LogoV3 from '../images/logoV3.svg'
import OperatorSvg from '../images/operator.svg'
import patientSvg from '../images/patient.svg'
import PlatformSvg from '../images/platform.svg'
import scheduleSvg from '../images/schedule.svg'
import ScreenerSvg from '../images/screener.svg'
import StudiosSvg from '../images/studios.svg'
import supportSvg from '../images/support.svg'
import TeamSvg from '../images/team.svg'
import UsersSvg from '../images/users.svg'
import { Doctors } from '../pages/Admin/Doctors'
import ViewDoctor from '../pages/Admin/Doctors/id'
import Growth from '../pages/Admin/Growth'
import { Patients } from '../pages/Admin/Patients'
import { ViewPatientDetails } from '../pages/Admin/Patients/id'
import Platform from '../pages/Admin/Platform'
import Screener from '../pages/Admin/Screener'
import ViewScreener from '../pages/Admin/Screener/id'
import { Studios } from '../pages/Admin/Studios'
import ViewStudio from '../pages/Admin/Studios/id'
import Support from '../pages/Admin/Support/Support'
import { Users } from '../pages/Admin/Users'
import Login from '../pages/Login'
import Home from '../pages/Old/Home/Home'
import Operator from '../pages/Old/Operator/Operator'
import OperatorUserTable from '../pages/Old/Operator/OperatorUser/Table/OperatorUserTable'
import ScreenerEdit from '../pages/Old/Screener/EditScreener/ScreenerEdit'
import Team from '../pages/Old/Team/Team'
import { Chiropractor } from '../pages/Operator/Chiropractor'
import EditChiropractorPage from '../pages/Operator/Chiropractor/id/EditChiropractorPage'
import { ClinicHome } from '../pages/Operator/Clinic'
import EditClinicHome from '../pages/Operator/Clinic/id'
import OperatorHome from '../pages/Operator/Home/Home'
import { TeamHome } from '../pages/Operator/Team'
import ScreenerHome from '../pages/Screener/Appointments'
import PatientDetailsPage from '../pages/Screener/Appointments/id/PatientDetailsPage'
import { AvailabiltiesScreen } from '../pages/Screener/Availabitlities'
import { PatientScreen } from '../pages/Screener/Patients'
import { PatientDetailScreen } from '../pages/Screener/Patients/id'
import { resetChosenStudio } from '../redux/admin/growth'
import { setTablePage } from '../redux/admin/patient'
import { setTableCurrentPage } from '../redux/screener/patients'
import { useAppDispatch, useAppSelector } from '../redux/store'

const RoleBasedRoute = () => {
  const { isAuthLoading, isAuthenticated, signOut, user, currentRole, setCurrentRole } = useAuth()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const tableCurrentPage = useAppSelector((state) => state.admin.patient.currentPage)
  const pathSegments = location.pathname.split('/').filter(Boolean)
  const AdminSelectedKey = pathSegments.length > 1 ? [pathSegments[1]] : ['doctors']
  const screenerSelectedKey = pathSegments.length > 1 ? [pathSegments[1]] : ['appointments']
  const operatorSelectedKey = pathSegments.length > 1 ? [pathSegments[1]] : ['home']
  const verifyOtpStatus = useAppSelector((state) => state.me.verifyOptStatus)
  const navigate = useNavigate()
  console.log(currentRole, 'current role')

  const resetPatientTablePage = () => {
    if (tableCurrentPage) {
      dispatch(setTablePage(null))
    }
  }

  if (isAuthLoading) {
    return (
      <div className={style.body}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <img src={Logo} className={style.logo} alt="Klogo"></img>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (user && user?.roles && user?.roles?.filter((r: any) => r !== 'PATIENT').length > 1 && !currentRole) {
    return (
      <div className={style.body}>
        <div className={style.container}>
          <div className={style.header}>
            <span className={style.loginAsText}>Login As</span>
          </div>

          <div className={style.tabs}>
            {user?.roles.map((role: string, index: number) => {
              if (role === 'SCREENER') {
                return (
                  <Button
                    key={index}
                    onClick={() => {
                      setCurrentRole(role)
                    }}
                  >
                    Screener
                  </Button>
                )
              } else if (role === 'PLATFORM_USER') {
                return (
                  <Button
                    key={index}
                    onClick={() => {
                      setCurrentRole(role)
                    }}
                  >
                    Admin
                  </Button>
                )
              } else if (role === 'OPERATOR_USER') {
                return (
                  <Button
                    key={index}
                    onClick={() => {
                      setCurrentRole(role)
                    }}
                  >
                    Operator
                  </Button>
                )
              }
            })}
          </div>
        </div>
      </div>
    )
  } else if (user && currentRole === 'PLATFORM_USER') {
    return (
      <Layout style={{ height: '100vh', width: '100vw' }}>
        <Helmet>
          <title>KIRO | ADMIN</title>
        </Helmet>
        <Sider style={{ borderRight: '1px solid #2D2C3C', fontWeight: 550 }} breakpoint="xl">
          <div className={style.LogoContainer}>
            <img src={LogoV3} className={style.logo} alt="logo"></img>
          </div>
          <Menu
            onClick={(e) => {
              if (e.key === 'doctors') {
                resetPatientTablePage()
                navigate('/admin/doctors')
              }
              // else if (e.key === 'screeners') {
              //   resetPatientTablePage()
              //   navigate('/admin/screeners')
              // }
              else if (e.key === 'studios') {
                resetPatientTablePage()
                dispatch(resetChosenStudio())
                navigate('/admin/studios')
              } else if (e.key === 'platform') {
                resetPatientTablePage()
                dispatch(resetChosenStudio())
                navigate('/admin/platform')
                navigate('/admin/platform/city')
              } else if (e.key === 'users') {
                resetPatientTablePage()
                dispatch(resetChosenStudio())
                navigate('/admin/users')
              } else if (e.key === 'patients') {
                dispatch(resetChosenStudio())
                navigate('/admin/patients')
              } else if (e.key === 'growth') {
                dispatch(resetChosenStudio())
                navigate('/admin/growth')
              }
            }}
            onSelect={(e) => {
              if (e.key === 'doctors') {
                resetPatientTablePage()
                dispatch(resetChosenStudio())
                navigate('/admin/doctors')
              }
              // else if (e.key === 'screeners') {
              //   resetPatientTablePage()
              //   navigate('/admin/screeners')
              // }
              else if (e.key === 'studios') {
                resetPatientTablePage()
                dispatch(resetChosenStudio())
                navigate('/admin/studios')
              } else if (e.key === 'platform') {
                resetPatientTablePage()
                dispatch(resetChosenStudio())
                navigate('/admin/platform')
                navigate('/admin/platform/city')
              } else if (e.key === 'users') {
                resetPatientTablePage()
                dispatch(resetChosenStudio())
                navigate('/admin/users')
              } else if (e.key === 'patients') {
                navigate('/admin/patients')
                dispatch(resetChosenStudio())
              } else if (e.key === 'growth') {
                dispatch(resetChosenStudio())
                navigate('/admin/growth')
              }
            }}
            defaultSelectedKeys={AdminSelectedKey}
            items={[
              // {
              //   key: 'home',
              //   icon: <img src={HomeSvg} alt="Home" draggable="false"></img>,
              //   label: 'Home',
              // },
              {
                key: 'doctors',
                icon: <img src={DoctorsSvg} alt="Doctors" draggable="false"></img>,
                label: 'Doctors',
              },
              // {
              //   key: 'screeners',
              //   icon: <img src={ScreenerSvg} alt="Screener" draggable="false"></img>,
              //   label: 'Screeners',
              // },
              {
                key: 'studios',
                icon: <img src={StudiosSvg} alt="Studios" draggable="false"></img>,
                label: 'Studios',
              },
              {
                key: 'platform',
                icon: <img src={PlatformSvg} alt="Platform" draggable="false"></img>,
                label: 'Platform',
              },
              {
                key: 'users',
                icon: <img src={UsersSvg} alt="Users" draggable="false"></img>,
                label: 'Users',
              },
              {
                key: 'patients',
                icon: <img src={patientSvg} alt="patients" draggable="false"></img>,
                label: 'Patients',
              },
              {
                key: 'growth',
                icon: <img src={growthSvg} alt="growth" draggable="false"></img>,
                label: 'Growth',
              },
            ]}
          />
          <div
            className={style.SignoutContainer}
            onClick={() => {
              signOut().finally(() => {})
            }}
          >
            <img src={LogoutSvg} alt="Logout" draggable="false"></img>
            <span>Logout</span>
          </div>
        </Sider>
        <Routes>
          {/* <Route path="/admin/home" element={<Home></Home>} /> */}
          <Route path="/" element={<Doctors />} />
          <Route path="/admin/doctors/" element={<Doctors />} />
          <Route path="/admin/doctors/:id" element={<ViewDoctor />} />
          {/* <Route path="/admin/screeners/" element={<Screener />}></Route>
          <Route path="/admin/screeners/:id" element={<ViewScreener />} /> */}
          <Route path="/admin/studios/" element={<Studios />}></Route>
          <Route path="/admin/studios/:id" element={<ViewStudio />}></Route>
          <Route path="platform/" element={<Platform />} />
          <Route path="/admin/platform/:tab" element={<Platform />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/patients" element={<Patients />} />
          <Route path="/admin/patients/:id" element={<ViewPatientDetails />} />
          <Route path="/admin/growth" element={<Growth />} />
        </Routes>
      </Layout>
    )
  } else if (user && currentRole === 'SCREENER') {
    return (
      <Layout style={{ height: '100vh', width: '100vw' }}>
        <Helmet>
          <title>KIRO | SCREENER</title>
        </Helmet>
        <Sider style={{ borderRight: '1px solid #2D2C3C', fontWeight: 550 }} breakpoint="xl">
          <div className={style.LogoContainer}>
            <img src={LogoV3} className={style.logo} alt="logo"></img>
          </div>
          <Menu
            onClick={(e) => {
              if (e.key === 'availabilities') {
                dispatch(setTableCurrentPage(null))
                navigate(`/screener/availabilities/${user.userId}`)
              } else if (e.key === 'appointments') {
                dispatch(setTableCurrentPage(null))
                navigate('/screener/appointments')
              } else if (e.key === 'patients') {
                navigate('/screener/patients')
              }
            }}
            onSelect={(e) => {
              if (e.key === 'availabilities') {
                dispatch(setTableCurrentPage(1))
                navigate(`/screener/availabilities/${user.userId}`)
              } else if (e.key === 'appointments') {
                dispatch(setTableCurrentPage(1))
                navigate('/screener/appointments')
              } else if (e.key === 'patients') {
                navigate('/screener/patients')
              }
            }}
            defaultSelectedKeys={screenerSelectedKey}
            items={[
              {
                key: 'appointments',
                icon: <img src={appointmentsSvg} alt="appointments" draggable="false"></img>,
                label: 'Appointments',
              },
              {
                key: 'availabilities',
                icon: <img src={scheduleSvg} alt="schedule" draggable="false"></img>,
                label: 'Schedule',
              },
              {
                key: 'patients',
                icon: <img src={patientSvg} alt="schedule" draggable="false"></img>,
                label: 'Patients',
              },
            ]}
          />
          {/* <Menu
            style={{ bottom: 0, position: 'relative', marginBottom: 100 }}
            onClick={(e) => {
              window.location = '/'
              signOut()
            }}
            onSelect={(e) => {}}
            defaultSelectedKeys={screenerSelectedKey}
            items={[
              {
                key: 'Logout',
                icon: <LogoutOutlined />,
                label: 'Logout',
              },
            ]}
          /> */}
          <div
            className={style.SignoutContainer}
            onClick={() => {
              signOut().finally(() => {})
            }}
          >
            <img src={LogoutSvg} alt="Logout" draggable="false"></img>
            <span>Logout</span>
          </div>
        </Sider>
        <Routes>
          <Route path="/" element={<ScreenerHome></ScreenerHome>} />
          <Route path="/screener/appointments" element={<ScreenerHome></ScreenerHome>} />
          <Route path="/screener/availabilities/:id" element={<AvailabiltiesScreen></AvailabiltiesScreen>} />
          <Route path="/screener/patients" element={<PatientScreen></PatientScreen>} />
          <Route path="/screener/patients/:id" element={<PatientDetailScreen></PatientDetailScreen>} />
          <Route path="/screener/appointment/:id" element={<PatientDetailsPage></PatientDetailsPage>} />
        </Routes>
      </Layout>
    )
  } else if (user && currentRole === 'ANALYTICS_VIEWER') {
    return (
      <Layout style={{ height: '100vh', width: '100vw' }}>
        <Helmet>
          <title>KIRO | ANALYTICS</title>
        </Helmet>
        <Sider style={{ borderRight: '1px solid #2D2C3C', fontWeight: 550 }} breakpoint="xl">
          <div className={style.LogoContainer}>
            <img src={LogoV3} className={style.logo} alt="logo"></img>
          </div>
          <Menu
            onClick={(e) => {
              if (e.key === 'growth') {
                navigate(`/analytics/growth`)
              }
            }}
            onSelect={(e) => {
              if (e.key === 'growth') {
                navigate(`/analytics/growth`)
              }
            }}
            defaultSelectedKeys={['growth']}
            items={[
              {
                key: 'growth',
                icon: <img src={growthSvg} alt="schedule" draggable="false"></img>,
                label: 'Growth',
              },
            ]}
          />
          {/* <Menu
            style={{ bottom: 0, position: 'relative', marginBottom: 100 }}
            onClick={(e) => {
              window.location = '/'
              signOut()
            }}
            onSelect={(e) => {}}
            defaultSelectedKeys={screenerSelectedKey}
            items={[
              {
                key: 'Logout',
                icon: <LogoutOutlined />,
                label: 'Logout',
              },
            ]}
          /> */}
          <div
            className={style.SignoutContainer}
            onClick={() => {
              signOut().finally(() => {})
            }}
          >
            <img src={LogoutSvg} alt="Logout" draggable="false"></img>
            <span>Logout</span>
          </div>
        </Sider>
        <Routes>
          <Route path="/" element={<Growth />} />
          <Route path="/analytics/growth" element={<Growth />} />
        </Routes>
      </Layout>
    )
  } else if (user && currentRole === 'CUSTOMER_SUPPORT') {
    return (
      <Layout style={{ height: '100vh', width: '100vw' }}>
        <Helmet>
          <title>KIRO | SUPPORT</title>
        </Helmet>
        <Sider style={{ borderRight: '1px solid #2D2C3C', fontWeight: 550 }} breakpoint="xl">
          <div className={style.LogoContainer}>
            <img src={LogoV3} className={style.logo} alt="logo"></img>
          </div>
          <Menu
            onClick={(e) => {
              if (e.key === 'support') {
                navigate(`/support`)
              }
            }}
            onSelect={(e) => {
              if (e.key === 'support') {
                navigate(`/support`)
              }
            }}
            defaultSelectedKeys={['support']}
            items={[
              {
                key: 'support',
                icon: <img src={supportSvg} alt="support" draggable="false"></img>,
                label: 'Support',
              },
            ]}
          />
          <div
            className={style.SignoutContainer}
            onClick={() => {
              signOut().finally(() => {})
            }}
          >
            <img src={LogoutSvg} alt="Logout" draggable="false"></img>
            <span>Logout</span>
          </div>
        </Sider>
        <Routes>
          <Route path="/" element={<Support />} />
          <Route path="/support" element={<Support />} />
          <Route path="/support/patients/:id" element={<ViewPatientDetails />} />
        </Routes>
      </Layout>
    )
  } else if (user && currentRole === 'OPERATOR_USER') {
    return (
      <Layout style={{ height: '100vh', width: '100vw' }}>
        <Sider style={{ borderRight: '1px solid #2D2C3C' }} breakpoint="xl">
          <div className={style.LogoContainer}>
            <img src={LogoV3} className={style.logo} alt="logo"></img>
          </div>
          <Menu
            onClick={(e) => {
              if (e.key === 'home') {
                navigate(`/operator/home`)
              } else if (e.key === 'clinic') {
                navigate('/operator/clinic')
              } else if (e.key === 'chiropractor') {
                navigate('/operator/chiropractor')
              } else if (e.key === 'team') {
                navigate('/operator/team')
              }
            }}
            onSelect={(e) => {
              if (e.key === 'home') {
                navigate(`/operator/home`)
              } else if (e.key === 'clinic') {
                navigate('/operator/clinic')
              } else if (e.key === 'chiropractor') {
                navigate('/operator/chiropractor')
              } else if (e.key === 'team') {
                navigate('/operator/team')
              }
            }}
            defaultSelectedKeys={operatorSelectedKey}
            items={[
              {
                key: 'home',
                icon: <img src={HomeSvg} alt="Home" draggable="false"></img>,
                label: 'Home',
              },
              {
                key: 'clinic',
                icon: <img src={ClinicSvg} alt="Clinic" draggable="false"></img>,
                label: 'Clinic',
              },
              {
                key: 'chiropractor',
                icon: <img src={ChiropractorSvg} alt="Chiropractor" draggable="false"></img>,
                label: 'Chiropractor',
              },
              {
                key: 'team',
                icon: <img src={TeamSvg} alt="Team" draggable="false"></img>,
                label: 'Team',
              },
            ]}
          />
          <div
            className={style.SignoutContainer}
            onClick={() => {
              signOut().finally(() => {})
            }}
          >
            <img src={LogoutSvg} alt="Logout" draggable="false"></img>
            <span>Logout</span>
          </div>
        </Sider>
        <Routes>
          <Route path="/" element={<OperatorHome></OperatorHome>} />
          <Route path="/operator/home" element={<OperatorHome></OperatorHome>} />
          <Route path="/operator/clinic" element={<ClinicHome></ClinicHome>} />
          <Route path="/operator/clinic/:id" element={<EditClinicHome></EditClinicHome>} />
          <Route path="/operator/chiropractor" element={<Chiropractor></Chiropractor>} />
          <Route path="/operator/chiropractor/:id" element={<EditChiropractorPage />} />
          <Route path="/operator/team/" element={<TeamHome />} />
        </Routes>
      </Layout>
    )
  } else {
    return (
      <Routes>
        <Route path="*" element={<Login></Login>} />
      </Routes>
    )
  }
}

export default RoleBasedRoute
