import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../../services/interface'
import { ApiGet } from '../../../services/Service'
import { StatusEnum } from '../../type'
import { IPatients } from '../patient/interface'

export interface SupportState {
  filteredPatientSupport: Array<IPatients>
  searchValue: string
  noPatientFoundTag: boolean
  filteredPatientSupportStatus: StatusEnum
  error: any
}

const initialState: SupportState = {
  filteredPatientSupport: [],
  searchValue: '',
  noPatientFoundTag: false,
  filteredPatientSupportStatus: StatusEnum.Idle,
  error: null,
}

export const getFilteredPatientSupportAsync = createAsyncThunk(
  'support/getFilteredPatientSupportAsync',
  async ({ searchText }: { searchText: string }, thunkAPI) => {
    const response: APIResponse = await ApiGet(`/patient?searchText=${searchText}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        metaData: response.data.metaData,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)
export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    resetFilteredPatients: (state) => {
      state.filteredPatientSupport = []
    },
    resetnoPatientFoundTag: (state) => {
      state.noPatientFoundTag = false
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
  },
  extraReducers(builder) {
    //getFilteredPatientSupportAsync
    builder.addCase(getFilteredPatientSupportAsync.pending, (state) => {
      state.filteredPatientSupportStatus = StatusEnum.Pending
    })
    builder.addCase(getFilteredPatientSupportAsync.fulfilled, (state, action) => {
      state.filteredPatientSupport = action.payload.data
      if (action.payload.data.length === 0) {
        state.noPatientFoundTag = true
      } else {
        state.noPatientFoundTag = false
      }
      state.filteredPatientSupportStatus = StatusEnum.Success
      state.error = action.payload
    })
    builder.addCase(getFilteredPatientSupportAsync.rejected, (state) => {
      state.filteredPatientSupportStatus = StatusEnum.Failed
    })
  },
})

export default supportSlice.reducer

export const { resetFilteredPatients, resetnoPatientFoundTag, setSearchValue } = supportSlice.actions
