import { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Row, Button, Segmented, Radio, RadioChangeEvent, Modal, Flex, Avatar, Select, Input } from 'antd'
import { SegmentedValue } from 'antd/es/segmented'
import { UploadProps } from 'antd/lib'
import { format, isAfter, isBefore } from 'date-fns'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styles from './PatientInfo.module.scss'
import { AppConstants } from '../../../../AppContants'
import Loading from '../../../../components/Loading/Loading'
import crossSvg from '../../../../images/cross.svg'
import cross from '../../../../images/crossColored.svg'
import arrow from '../../../../images/dropArrow.svg'
import KiroLogo from '../../../../images/kiro-logo-full.svg'
import KiroLogoRed from '../../../../images/kiro-Logo-Red.svg'
import tick from '../../../../images/tick.svg'

import {
  addAdjustmentCountAsync,
  getPatientByIdAsync,
  getPatientNotesByIdAsync,
  getVisitByPatientIdAsync,
  postCancelAppointmentAsync,
  postScreenerFeedbackAsync,
  removeAdjustmentCountAsync,
  resetFeedbackPostStatus,
  resetGetVisitByPatientId,
  resetEditAdjustmentCount,
  resetuploadFileResponse,
  resetuploadFileResponseCode,
  setuploadFileResponse,
  setuploadFileResponseCode,
  resetAddAdjustmentCountStatus,
  resetRemoveAdjustmentCountStatus,
} from '../../../../redux/admin/patient'
import { IAppointment, INotes, IPatients, IPatientVisit } from '../../../../redux/admin/patient/interface'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { IQandA, Questions, StatusEnum, VisitStatus } from '../../../../redux/type'
import { calculateDob } from '../../../../utils/Dob'
import { formatPhoneNumber } from '../../../../utils/FormatPhoneNumber'
import { NotificaitonPlacement, notifyError } from '../../../../utils/Notification'

interface PatientDetailsProps {
  patient: IPatients
  patientVisit?: IPatientVisit
  questionAndAnswer: Record<string, IQandA[]>
  questions: Questions[]
}
interface PatientHealth {
  onClick?: () => void
  title?: any
  options?: any
}

const PatientInfo: React.FC<PatientDetailsProps> = ({ patient, patientVisit, questionAndAnswer, questions }) => {
  const dispatch = useAppDispatch()
  const { id }: any = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [reason, setReason]: any = useState('')
  const [fileList, setFileList] = useState<any>('')
  const fileUploadResponse = useAppSelector((state) => state.admin.patient.uploadFileResponse)
  const screenerFeedback = useAppSelector((state) => state.admin.patient.screenerFeedback)
  const postScreenerFeedbackStatus = useAppSelector((state) => state.admin.patient.feedbackPostStatus)
  const visit = useAppSelector((state) => state.admin.patient.visit)
  const cancelledAppointment = useAppSelector((state) => state.admin.patient.postCancelAppointment)
  const [loading, setLoading] = useState(false)
  const visitStatus = useAppSelector((state) => state.admin.patient.getVisitStatus)
  const patientByIdStatus = useAppSelector((state) => state.admin.patient.getPatientByIdStatus)
  const adjustmentCount = useAppSelector((state) => state.admin.patient.editAdjustmentCount)
  const notes = useAppSelector((state) => state.admin.patient.notesByPatientId)
  const [activeTab, setActiveTab] = useState<SegmentedValue>('Appointments')
  const [radioBtnSelected, setRadioBtnSelected] = useState('upcoming')
  const [selectedNote, setSelectedNote] = useState({
    subjective: '',
    objective: '',
    assessment: '',
    plan: '',
  })
  const [showModal, setShowModal] = useState(false)
  const editAdjustmentCount = useAppSelector((state) => state.admin.patient.editAdjustmentCount)

  const upcomingAppointments = useMemo(() => {
    const now = new Date()
    return visit?.filter((r: IAppointment) => {
      if (r.status !== VisitStatus.booked) return false
      const appointmentDate = new Date(r?.appointmentTime ?? format(r.appointmentTime || '', 'yyyy-MM-dd'))
      return isAfter(appointmentDate, now)
    })
  }, [visit, cancelledAppointment])

  const missedAppointments = useMemo(() => {
    const now = new Date()
    return visit?.filter((r: IAppointment) => {
      if (r.status !== VisitStatus.booked) return false
      const appointmentDate = new Date(r?.appointmentTime ?? format(r.appointmentTime || '', 'yyyy-MM-dd'))
      return isBefore(appointmentDate, now)
    })
  }, [visit, cancelledAppointment])

  const cancelledAppointments = useMemo(() => {
    return visit?.filter((r: IAppointment) => {
      return r.status === VisitStatus.cancelled
    })
  }, [visit, cancelledAppointment])

  // let appointmentDateTime = useMemo(() => {
  //   if (visit) {
  //     return {
  //       date: format(new Date(visit.appointmentTime), 'EEE d'),
  //       time: format(new Date(visit.appointmentTime), 'h:mm a'),
  //     }
  //   }
  // }, [visit])
  useEffect(() => {
    const fileUrl: any = patient?.screeningSessionRecording

    if (fileUrl) {
      const decodedFileName = decodeURIComponent(fileUrl.split('/').pop())
      setFileList(decodedFileName)
    }
    return () => {
      dispatch(resetFeedbackPostStatus(null))
    }
  }, [patient])

  useEffect(() => {
    if (fileUploadResponse?.code === 'success') {
      setLoading(false)
      const decodedFileName = decodeURIComponent(fileUploadResponse?.data?.screeningSessionRecording.split('/').pop())
      setFileList(decodedFileName)
    }
  }, [fileUploadResponse])

  useEffect(() => {
    dispatch(getVisitByPatientIdAsync(id))
    return () => {
      dispatch(resetGetVisitByPatientId(null))
      dispatch(resetuploadFileResponse())
      dispatch(resetEditAdjustmentCount())
    }
  }, [])

  const initialLetters = useMemo(() => {
    if (patient === null) {
      return null
    }
    let firstName = patient?.user?.firstname ? patient?.user?.firstname[0]?.toUpperCase() : ''
    let lastName = patient?.user?.lastname ? patient?.user?.lastname[0]?.toUpperCase() : ''
    return firstName + lastName
  }, [patient])

  const filteredQuestions: any = useMemo(() => {
    const filtered: any = questions.filter((item: any) => {
      const isMale = patient?.user?.gender === 'male'
      const isPregnancyIcon = item.iconIdentifier === 'pregnancy'
      let hasNoneOptions = false

      for (const iq of questionAndAnswer[(id && id) ?? ''] ?? []) {
        if (iq.questionId === item.id) {
          for (const o of iq?.responseOptions ?? []) {
            if (o?.option?.text?.toLowerCase() === 'none' || o?.option?.text?.toLowerCase() === 'no') {
              hasNoneOptions = true
              break
            }
          }
          if (hasNoneOptions === true) {
            break
          }
        }
      }
      return !((isMale && isPregnancyIcon) || hasNoneOptions)
    })
    return filtered
  }, [questions, questionAndAnswer])

  const patientAge: any = useMemo(() => {
    const patientDOB = patient?.user?.dob
    if (patientDOB) {
      const age = calculateDob(patientDOB)
      return age
    } else {
      return '-'
    }
  }, [patient])

  const handleFileChange: UploadProps['onChange'] = useCallback(
    (info: any) => {
      setLoading(true)
      dispatch(setuploadFileResponse(info?.file?.response))
      dispatch(setuploadFileResponseCode(info?.file?.response?.code))
      dispatch(resetuploadFileResponseCode())

      // setFileList(info.fileList)
    },
    [dispatch],
  )
  useEffect(() => {
    setReason(patient?.screenerFeedback)
  }, [patient?.screenerFeedback])

  const props: UploadProps = {
    action: process.env.REACT_APP_API_BASE_URL + `/patient/${patient?.id}/screening/recording`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AppConstants.ACCESS_TOKEN)}`,
      'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
    },
    onChange: handleFileChange,

    multiple: true,
  }

  const handleApprove = () => {
    dispatch(
      postScreenerFeedbackAsync({
        id: patient.id,
        screeningStatus: 'accepted',
        //visitId: visit.id,
        screenerFeedback: reason || '',
      }),
    )
  }

  useEffect(() => {
    if (postScreenerFeedbackStatus === StatusEnum.Success) {
      if (location.pathname.includes('admin')) {
        navigate('/admin/patients')
      }
    }
  }, [screenerFeedback])

  useEffect(() => {
    dispatch(getPatientNotesByIdAsync({ patientId: id }))
  }, [])

  const handleReject = () => {
    dispatch(
      postScreenerFeedbackAsync({
        id: patient.id,
        screeningStatus: 'rejected',
        //visitId: visit.id,
        screenerFeedback: reason || '',
      }),
    )
  }

  useEffect(() => {
    const handleEscKey = (e: any) => {
      if (e.key === 'Escape') {
        navigate(-1)
      }
    }
    document.addEventListener('keydown', handleEscKey)
    return () => {
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [])

  useEffect(() => {
    if (editAdjustmentCount !== null) {
      dispatch(getPatientByIdAsync({ id }))
    }
  }, [editAdjustmentCount])

  const handleTabChange = (value: SegmentedValue) => {
    setActiveTab(value)
  }

  const handleRadioBtnChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value)
    setRadioBtnSelected(e.target.value)
  }

  if (patientByIdStatus === StatusEnum.Pending) {
    return (
      <div>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  return (
    <div className={styles.patientDetails}>
      {/* <div className={styles.patientDetailsHeading}>Patient Details</div> */}
      <div className={styles.patientProfileContainer}>
        <div className={styles.patientProfile}>
          <div className={styles.patientProfileAndInfo}>
            {!patient?.user?.profilePic && (
              // <div className={styles.nameTag}>
              //   <div className={styles.initialLetters}>{initialLetters}</div>
              // </div>
              <div className={styles.patientProfileImg}>
                <Avatar src={''} shape="square" style={{ borderRadius: 8, minWidth: '126px', minHeight: '126px' }}>
                  <div style={{ fontSize: '5rem' }}>{initialLetters}</div>
                </Avatar>
                {patient?.membership && (
                  <img
                    className={styles.badge}
                    src={patient?.membershipStatus === 'in-active' ? KiroLogoRed : KiroLogo}
                    alt="logo"
                    style={{ marginLeft: '8px', background: 'none' }}
                  ></img>
                )}
                {patient?.user?.dob && (
                  <div className={styles.badge} style={{ left: '105px', top: '20px' }}>
                    {calculateDob(patient?.user?.dob)}
                  </div>
                )}
                {patient?.isNew && (
                  <div
                    children={'New'}
                    className={styles.badge}
                    style={{ left: '28px', width: '40px', height: '23px', top: '107px' }}
                  ></div>
                )}
              </div>
            )}
            {patient?.user?.profilePic && (
              <div className={styles.patientProfileImg}>
                <Avatar
                  src={patient?.user?.profilePic}
                  shape="square"
                  style={{
                    borderRadius: 8,
                    minWidth: '126px',
                    minHeight: '126px',
                  }}
                ></Avatar>

                {patient?.membership && (
                  <img
                    className={styles.badge}
                    src={patient?.membershipStatus === 'in-active' ? KiroLogoRed : KiroLogo}
                    alt="logo"
                    style={{ marginLeft: '8px', background: 'none' }}
                  ></img>
                )}
                {patient?.user?.dob && (
                  <div className={styles.badge} style={{ left: '105px', top: '20px' }}>
                    {calculateDob(patient?.user?.dob)}
                  </div>
                )}
                {patient?.isNew && (
                  <div
                    children={'New'}
                    className={styles.badge}
                    style={{ left: '28px', width: '40px', height: '23px', top: '107px' }}
                  ></div>
                )}
              </div>
            )}
            <div className={styles.patientInfo}>
              <div className={styles.patientName} style={{ minWidth: '150px', textTransform: 'capitalize' }}>
                {' ' + (patient?.user?.firstname || '') + ' ' + (patient?.user?.lastname || '')}
              </div>
              <div
                className={styles.patientInfoElement}
                style={{ minWidth: '100px', cursor: 'pointer' }}
                onClick={() => {
                  if (patient?.user?.phone) {
                    window.location.href = `tel:${patient?.user?.phone}`
                  }
                }}
              >
                {patient?.user?.phone ? formatPhoneNumber(patient?.user?.phone) : '-'}
              </div>
              <div
                className={styles.patientInfoElement}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (patient?.user?.email || patient?.user?.unverifiedEmail) {
                    window.location.href = patient?.user?.email
                      ? `mailto:${patient?.user?.email}`
                      : `mailto:${patient?.user?.unverifiedEmail}`
                  }
                }}
              >
                {patient?.user?.email || patient?.user?.unverifiedEmail
                  ? patient?.user?.email
                    ? patient?.user?.email
                    : patient?.user?.unverifiedEmail
                  : '-'}
              </div>
              <div className={styles.patientInfoElement} style={{ textTransform: 'capitalize' }}>
                {patient?.source ? patient?.source : '-'}{' '}
              </div>
              {/* <div className={styles.patientNameAgeGender}>{' ' + patientAge} </div> */}
              {/* <div
              className={styles.patientAgeGender}
            >{`${patientAge}${patient?.user?.gender !== null ? (patient?.user?.gender?.toLowerCase() === 'others' ? ', Other' : ', ' + patient?.user?.gender) : ''}`}</div> */}
            </div>
          </div>
          <div className={styles.metricWrapper}>
            <div className={styles.rowOne}>
              {patient?.feedbackValue === null ? (
                <div className={styles.flashingMetricCard}>
                  <div className={styles.metricTitle}>REV</div>
                  <div className={styles.metricValue}>{patient?.feedbackValue ?? '-'}</div>
                </div>
              ) : (
                <div className={styles.metricCard}>
                  <div className={styles.metricTitle}>REV</div>
                  <div className={styles.metricValue}>{patient?.feedbackValue ?? '-'}</div>
                </div>
              )}

              <div className={styles.metricCard}>
                <div className={styles.metricTitle}>ATD</div>
                <div className={styles.metricValue}>{patient?.metric?.atd ?? '-'}</div>
              </div>

              {patient && patient?.metric && patient?.metric?.dst > 7 ? (
                <div className={styles.flashingMetricCard}>
                  <div className={styles.metricTitle}>DSL</div>
                  <div className={styles.metricValue}>{patient?.metric?.dst ?? '-'}</div>
                </div>
              ) : (
                <div className={styles.metricCard}>
                  <div className={styles.metricTitle}>DSL</div>
                  <div className={styles.metricValue}>{patient?.metric?.dst ?? '-'}</div>
                </div>
              )}
              {patient && patient?.metric && patient?.metric?.apm && parseFloat(patient?.metric?.apm) < 4 ? (
                <div className={styles.flashingMetricCard}>
                  <div className={styles.metricTitle}>APM</div>
                  <div className={styles.metricValue}>{patient?.metric?.apm ?? '-'}</div>
                </div>
              ) : (
                <div className={styles.metricCard}>
                  <div className={styles.metricTitle}>APM</div>
                  <div className={styles.metricValue}>{patient?.metric?.apm ?? '-'}</div>
                </div>
              )}
            </div>
            <div className={styles.rowTwo}>
              {patient?.metric?.membershipWeek === 4 && (
                <div className={styles.flashingMetricCard}>
                  <div className={styles.metricTitle}>ZON</div>
                  <div className={styles.metricValue}>
                    {patient?.metric?.membershipMonth ?? '-'}.{patient?.metric?.membershipWeek ?? '-'}
                  </div>
                </div>
              )}
              {patient?.metric?.membershipWeek !== 4 && (
                <div
                  className={styles.metricCard}
                  style={{
                    backgroundColor: patient?.metric?.membershipWeek === 3 ? '#ff0000' : '#20212e',
                    color: patient?.metric?.membershipWeek === 3 ? '#e5e7eb' : '#007f7c',
                  }}
                >
                  <div className={styles.metricTitle}>ZON</div>
                  {patient?.metric?.membershipMonth === 0 && patient?.metric?.membershipWeek === 0 ? (
                    <div className={styles.metricValue}>{'-'}</div>
                  ) : (
                    <div className={styles.metricValue}>
                      {patient?.metric?.membershipMonth ?? '-'}.{patient?.metric?.membershipWeek ?? '-'}
                    </div>
                  )}
                </div>
              )}
              <div className={styles.metricCard}>
                <div className={styles.metricTitle}>PCR</div>
                <div className={styles.metricValue}>{patient?.subscriptionCount ?? 0}</div>
              </div>
              <div className={styles.metricCard}>
                <div className={styles.metricTitle}>NSS</div>
                <div className={styles.metricValue}>{patient?.metric?.scanScore ?? '-'}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.actionButtonsContainer}>
          <div className={styles.actionsContainer}>
            {/* {patient?.screeningStatus === 'not_booked' && (
              <Buttons
                children={'Screening not booked'}
                style={{
                  backgroundColor: 'transparent',
                  padding: 5,
                  color: '#007f7c',
                  border: '1px solid #007f7c',
                }}
              ></Buttons>
            )} */}
            {/* {patient?.screeningStatus === 'rejected' && (
              <Buttons
                children={'Rejected'}
                style={{
                  backgroundColor: 'transparent',
                  color: '#ff4141',
                  border: '1px solid #ff4141',
                }}
              ></Buttons>
            )} */}
            {/* {patient?.screeningStatus === 'accepted' && (
              <Buttons
                children={'Approved'}
                style={{
                  backgroundColor: 'transparent',
                  color: '#007f7c',
                  border: '1px solid #007f7c',
                }}
              ></Buttons>
            )} */}
            {/* {patient?.screeningStatus === 'screening_booked' && (
              <div className={styles.actionsContainer}>
                <Buttons
                  onClick={() => {
                    window.open(visit?.zoomLink, '_blank')
                  }}
                  style={{
                    opacity: visitStatus === StatusEnum.Pending ? '0.7' : '',
                    backgroundColor: '#0095D5',
                    backgroundImage: visitStatus === StatusEnum.Pending ? '' : `url(${zoom})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '15%  center',
                    paddingLeft: '2rem',
                    width: '179px',
                    color: '#fffeff',
                    height: '38px',
                    borderRadius: '0.4rem',
                    fontWeight: 700,
                  }}
                >
                  {visitStatus === StatusEnum.Pending ? (
                    <Spin
                      size="small"
                      indicator={<LoadingOutlined style={{ color: '#fff', right: '0.8rem' }} spin />}
                    />
                  ) : (
                    appointmentDateTime?.date + ', ' + appointmentDateTime?.time
                  )}
                </Buttons>

                <Buttons
                  onClick={() => {
                    handleReject()
                  }}
                  children={'Reject'}
                  style={{
                    backgroundColor: '#D50000',
                  }}
                ></Buttons>
                <Buttons
                  onClick={handleApprove}
                  children={'Approve'}
                  style={{ width: '179px', height: '38px', fontWeight: 700 }}
                ></Buttons>
              </div>
            )} */}
          </div>
          {/* <div>
            {patient?.screeningStatus === 'screening_booked' && (
              <div className={styles.dateIdentifier}>
                {visitStatus === StatusEnum.Pending && (
                  <Spin size="small" indicator={<LoadingOutlined style={{ color: '#fff', right: '0.8rem' }} spin />} />
                )}
                {visitStatus === StatusEnum.Success && (
                  <div>
                    <span>{appointmentDateTime?.date + ','}</span>
                    <span style={{ marginLeft: '5px' }}> {appointmentDateTime?.time}</span>
                  </div>
                )}
              </div>
            )}
          </div> */}
          {
            <div className={styles.creditWrapper}>
              <EditCredits patient={patient}></EditCredits>
              {/* <div className={styles.creditContainer}>
                {patientByIdStatus === StatusEnum.Success && (
                  <div className={styles.creditIdentifier}>
                    <span>
                      Available Credits:&nbsp;
                      {adjustmentCount?.remainingVisitCount ?? patient?.existingCount ?? '-'}
                    </span>
                  </div>
                )}
              </div> */}
            </div>
          }
        </div>
      </div>

      <div className={styles.patientDetailsContent}>
        {filteredQuestions.length !== 0 && <div className={styles.pastHealthConditionTitle}>Health Conditions</div>}
        <div className={styles.healthConditionsContainer}>
          <Row gutter={[10, 8]} align={'stretch'}>
            {filteredQuestions.map((item: any, index: number) => {
              let responseOptions: any = []
              let title: any
              for (const q in AppConstants.Questions) {
                if (item.iconIdentifier === q) {
                  title = AppConstants.Questions[q]
                  break
                }
              }
              questionAndAnswer[patient?.id]?.forEach((iq) => {
                if (iq.questionId === item.id) {
                  responseOptions = iq.responseOptions
                }
              })
              return (
                <Col xs={{ span: 17 }} sm={{ span: 17 }} md={{ span: 9 }} lg={{ span: 8 }} xl={{ span: 8 }} key={index}>
                  <div key={index}>
                    <PatientHealthCondition title={title} options={responseOptions}></PatientHealthCondition>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
        <div className={styles.toggleAppointments}>
          <Segmented options={['Appointments', 'Notes']} value={activeTab} onChange={handleTabChange}></Segmented>
        </div>
        {activeTab === 'Appointments' && (
          <div className={styles.radioBtnContainer}>
            <Radio.Group onChange={handleRadioBtnChange} value={radioBtnSelected}>
              <Radio value={'upcoming'} defaultChecked>
                Upcoming
              </Radio>
              <Radio value={'missed'}>Missed</Radio>
              <Radio value={'cancelled'}>Cancelled</Radio>
            </Radio.Group>
          </div>
        )}
        {activeTab === 'Appointments' && (
          <div className={styles.listContainer}>
            {radioBtnSelected === 'upcoming' && (
              <div className={styles.cardList}>
                {upcomingAppointments?.map((a: any) => (
                  <AppointmentCardItem
                    key={a.id}
                    hideCancelled={false}
                    appointments={a}
                    onCancel={() => {
                      dispatch(postCancelAppointmentAsync({ id: a.id }))
                    }}
                  />
                ))}
                {upcomingAppointments?.length === 0 && (
                  <div className={styles.noAppointments}>No upcoming appointments</div>
                )}
              </div>
            )}
            {radioBtnSelected === 'missed' && (
              <div className={styles.cardList}>
                {missedAppointments?.map((a: any) => (
                  <AppointmentCardItem
                    key={a.id}
                    appointments={a}
                    hideCancelled={true}
                    onCancel={() => {
                      dispatch(postCancelAppointmentAsync({ id: a.id }))
                    }}
                  />
                ))}
                {missedAppointments?.length === 0 && (
                  <div className={styles.noAppointments}>No missed appointments</div>
                )}
              </div>
            )}
            {radioBtnSelected === 'cancelled' && (
              <div className={styles.cardList}>
                {cancelledAppointments?.map((a: any) => (
                  <AppointmentCardItem key={a.id} appointments={a} hideCancelled={true} />
                ))}
                {cancelledAppointments?.length === 0 && (
                  <div className={styles.noAppointments}>No cancelled appointments</div>
                )}
              </div>
            )}
          </div>
        )}
        {activeTab === 'Notes' && (
          <div className={styles.listContainer}>
            <div className={styles.cardList}>
              {notes?.map((a: INotes) => (
                <NotesCard
                  key={a.id}
                  note={a}
                  onClick={() => {
                    setSelectedNote({
                      ...selectedNote,
                      subjective: a?.subjective ?? '',
                      objective: a?.objective ?? '',
                      assessment: a?.assessment ?? '',
                      plan: a?.plan ?? '',
                    })
                    setShowModal(true)
                  }}
                />
              ))}
              {notes?.length === 0 && <div className={styles.noAppointments}>No notes available</div>}
            </div>
          </div>
        )}
        {showModal && activeTab === 'Notes' && (
          <NotesModal
            closeModal={() => {
              setShowModal(false)
            }}
            showModal={showModal}
            note={selectedNote}
          ></NotesModal>
        )}

        {/* <div className={styles.feedbackTitle}>Feedback</div> */}
        {/* <TextArea
          className={styles.feedbackArea}
          autoSize={{ minRows: 6 }}
          onChange={(e) => {
            setReason(e.target.value)
          }}
          placeholder={'Insert comments here...'}
          value={reason}
          disabled={
            patient?.screeningStatus === 'not_booked' ||
            patient?.screeningStatus === 'rejected' ||
            patient?.screeningStatus === 'accepted'
              ? true
              : false
          }
        /> */}
        {/* <div className={styles.feedbackUploadContainer}>
          <div className={styles.feedbackContainer}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                paddingTop: '3rem',
                paddingBottom: '3rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className={styles.uploadBar}>
                <Upload.Dragger
                  {...props}
                  onChange={handleFileChange}
                  showUploadList={false}
                  maxCount={1}
                  style={{ border: 'none', backgroundColor: '#20212e' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className={styles.cloudUploadSign}>
                      <img src={cloudUpload} alt={'cloud upload'} className={styles.icon}></img>
                    </div>
                    <p className={styles.dragText}> Drag your file here</p>
                    <div className={styles.textContent}>OR</div>
                    <Button
                      children="Upload File"
                      style={{
                        width: '12.1rem',
                        backgroundImage: `url(${uploadButton})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '15%  center',
                        paddingLeft: '5rem',
                        paddingRight: '3rem',
                      }}
                    ></Button>
                  </div>
                </Upload.Dragger>
              </div>
            </div>
            {loading ? (
              <div className={styles.loadingContainer}>
                <Spin />
              </div>
            ) : fileList !== '' && fileList !== null ? (
              <div className={styles.uploadedFilesContainer}>
                <div className={styles.uploadedFiles}>
                  <div className={styles.uploadText}>Uploaded files</div>
                  <div className={styles.fileListBar}>
                    <img style={{ marginRight: '0.8rem' }} src={FileSvg} alt="FileSvg" />
                    <p
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {fileList}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div> */}
      </div>
    </div>
  )
}
export default PatientInfo

export function PatientHealthCondition({ title, options, onClick }: PatientHealth) {
  return (
    <div className={styles.questionnareParentContainer}>
      <div className={styles.questionnareContainer} onClick={onClick}>
        <div className={styles.questionnareTitle}>{title}</div>
        <div className={styles.optionsContainer}>
          {options && options.length > 0 ? (
            options.map((item: any, index: number) => (
              <div key={index} className={styles.optionItemsContainer}>
                <div className={styles.optionTick}>
                  <img src={tick} alt="tick sign" />
                </div>
                <div className={styles.optionItem}>{item?.option?.text}</div>
              </div>
            ))
          ) : (
            <div className={styles.optionItemsContainer}>
              <div className={styles.optionTick}>
                <img src={cross} alt="cross sign" />
              </div>
              <div className={styles.optionItem}>No answer yet</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

interface AppointmentCardItem {
  appointments?: IAppointment
  hideCancelled?: boolean
  isCancelled?: boolean
  onClick?: () => void
  onCancel?: () => void
}
export const AppointmentCardItem = ({
  appointments,
  onClick,
  isCancelled,
  onCancel,
  hideCancelled,
}: AppointmentCardItem) => {
  let appointmentDetails: any = useMemo(() => {
    if (appointments?.appointmentTime) {
      return {
        time: appointments?.appointmentTime ? format(appointments.appointmentTime, 'h:mm a') : '',
        date: appointments?.appointmentTime ? format(appointments.appointmentTime, 'MM-dd-yyyy') : '',
      }
    } else {
      return '-'
    }
  }, [appointments])

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.columnContainer}>
        <div className={styles.columnOneAndTwo}>
          <div className={styles.column}>
            {appointments && <span className={styles.columnTitleOne}>{appointmentDetails?.date || ''}</span>}
          </div>
          <div className={styles.column}>
            {<span className={styles.columnTitleTwo}>{appointmentDetails.time}</span>}
          </div>
        </div>
        {!hideCancelled && (
          <div className={styles.columnThree}>
            <div>{<Button className={styles.cancelBtn} children="Cancel" onClick={onCancel}></Button>}</div>
          </div>
        )}
      </div>
    </div>
  )
}

interface NotesCard {
  note?: INotes
  onClick?: () => void
}
export const NotesCard = ({ note, onClick }: NotesCard) => {
  let noteDetails: any = useMemo(() => {
    if (note?.createdAt) {
      return {
        date: note?.createdAt ? format(note?.createdAt, 'MM-dd-yyyy') : '',
        time: note?.createdAt ? format(note?.createdAt, 'h:mm a') : '',
      }
    } else {
      return null
    }
  }, [note])

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.columnContainer}>
        <div className={styles.columnOneAndTwo}>
          <div className={styles.column}>
            {note && (
              <span className={styles.columnTitleOne}>
                {' ' + note?.chiropractor?.user?.firstname + ' ' + note?.chiropractor?.user?.lastname}
              </span>
            )}
          </div>
          <div className={styles.column}>{<span className={styles.columnTitleTwo}>{noteDetails.date}</span>}</div>
        </div>
        <div className={styles.columnThree}>
          <div>
            {
              <Button
                className={styles.statusTag}
                children={note?.status && note?.status === 'accepted' ? 'Approved' : 'Pending'}
                onClick={() => {}}
                style={{
                  color: note?.status === 'pending' ? '#8c8678' : '#007f7c',
                  border: note?.status === 'pending' ? '1px solid #8c8678' : '1px solid #007f7c',
                }}
              ></Button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

interface NotesModal {
  note?: Note
  showModal: boolean
  closeModal: () => void
  onClick?: () => void
}

interface Note {
  subjective: string
  objective: string
  assessment: string
  plan: string
}

export const NotesModal = ({ note, showModal, closeModal }: NotesModal) => {
  return (
    <>
      <Modal
        open={showModal}
        centered
        closable={true}
        mask={true}
        maskClosable={true}
        footer={null}
        onCancel={closeModal}
        closeIcon={
          <div
            style={{
              width: '28px',
              height: '26px',
              position: 'absolute',
              top: -40,
              right: -45,
              background: '#191A23',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              closeModal()
            }}
          >
            <img src={crossSvg} alt="close icon"></img>
          </div>
        }
      >
        <Flex vertical gap={8}>
          <div className={styles.cardWrapper}>
            <div className={styles.textHeader}>Subjective</div>
            <div className={styles.textContainer}>{note?.subjective}</div>
          </div>

          <div className={styles.cardWrapper}>
            <div className={styles.textHeader}>Objective</div>
            <div className={styles.textContainer}>{note?.objective}</div>
          </div>
          <div className={styles.cardWrapper}>
            <div className={styles.textHeader}>Assessment</div>
            <div className={styles.textContainer}>{note?.assessment}</div>
          </div>
          <div className={styles.cardWrapper}>
            <div className={styles.textHeader}>Plan</div>
            <div className={styles.textContainer}>{note?.plan}</div>
          </div>
        </Flex>
      </Modal>
    </>
  )
}
interface Credits {
  patient: IPatients
}
export const EditCredits = ({ patient }: Credits) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const apiError = useAppSelector((state) => state.admin.patient.editAdjustmentError)
  const addAdjustmentCountStatus = useAppSelector((state) => state.admin.patient.addAdjustmentCountStatus)
  const removeAdjustmentCountStatus = useAppSelector((state) => state.admin.patient.removeAdjustmentCountStatus)
  const applyCredit = (planType: string, operationType: string) => {
    if (!id) {
      return
    }
    if (operationType === 'add-count') {
      dispatch(
        addAdjustmentCountAsync({
          patientId: id,
          count: '1',
          operationType: operationType,
          planType: planType,
        }),
      )
    } else if (operationType === 'decrease-count') {
      dispatch(
        removeAdjustmentCountAsync({
          patientId: id,
          count: '1',
          operationType: operationType,
          planType: planType,
        }),
      )
    }
  }

  useEffect(() => {
    if (removeAdjustmentCountStatus === StatusEnum.Failed) {
      notifyError('Unable to remove credit', apiError, NotificaitonPlacement.bottomRight)
    }
    if (addAdjustmentCountStatus === StatusEnum.Failed) {
      notifyError('Unable to add credit', NotificaitonPlacement.bottomRight)
    }
    return () => {
      dispatch(resetAddAdjustmentCountStatus())
      dispatch(resetRemoveAdjustmentCountStatus())
    }
  }, [removeAdjustmentCountStatus, addAdjustmentCountStatus])

  return (
    <div className={styles.creditsComponent}>
      <div className={styles.credits}>
        <div className={styles.type} style={{ color: !patient?.membership ? '#4f505e' : '#d2d3eo' }}>
          Plan
        </div>
        <div className={styles.operatorWrapper} style={{ marginLeft: 12 }}>
          <div
            className={styles.value}
            style={{ opacity: !patient?.membership ? 0.8 : 1, color: !patient?.membership ? '#4f505e' : '#d2d3eo' }}
          >
            {patient?.subscriptionCount ?? '-'}
          </div>
          <div
            className={styles.operator}
            style={{
              opacity: !patient?.membership || patient?.subscriptionCount === 0 ? 0.3 : 1,
              cursor: !patient?.membership || patient?.subscriptionCount === 0 ? 'not-allowed' : 'pointer',
            }}
            onClick={() =>
              !patient?.membership || patient?.subscriptionCount === 0
                ? undefined
                : applyCredit('subscription', 'decrease-count')
            }
          >
            <span style={{ marginTop: -2 }}>-</span>
          </div>
          <div
            className={styles.operator}
            style={{
              opacity: !patient?.membership ? 0.3 : 1,
              cursor: !patient?.membership ? 'not-allowed' : 'pointer',
            }}
            onClick={() => (patient?.membership ? applyCredit('subscription', 'add-count') : undefined)}
          >
            <span>+</span>
          </div>
        </div>
      </div>
      <div className={styles.credits} style={{ marginTop: 8 }}>
        <div className={styles.type}>Other</div>
        <div className={styles.operatorWrapper}>
          <div className={styles.value}>{patient?.onDemandCount ?? '-'}</div>
          <div
            className={styles.operator}
            style={{
              opacity: patient?.onDemandCount === 0 ? 0.3 : 1,
              cursor: patient?.onDemandCount === 0 ? 'not-allowed' : 'pointer',
            }}
            onClick={() => (patient.onDemandCount === 0 ? undefined : applyCredit('on-demand', 'decrease-count'))}
          >
            <span style={{ marginTop: -2 }}>-</span>
          </div>
          <div className={styles.operator} onClick={() => applyCredit('on-demand', 'add-count')}>
            <span>+</span>
          </div>
        </div>
      </div>
    </div>
  )
}
