import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Drawer, Flex, Form, Input, Select, Space, Switch } from 'antd'
import styles from './Plan.module.scss'
import Loading from '../../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../../components/Table'
import Arrow from '../../../../images/dropArrow.svg'
import {
  getPlansAsync,
  postPlanAsync,
  putPlanAsync,
  resetPostPlanStatus,
  resetPutPlanStatus,
} from '../../../../redux/admin/platform'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { StatusEnum } from '../../../../redux/type'
import { NotificaitonPlacement, notifyError, notifySuccess } from '../../../../utils/Notification'

const PlanTab = () => {
  const dispatch = useAppDispatch()

  const plans = useAppSelector((state) => state.admin.platform.plans)
  const plansMetaData: any = useAppSelector((state) => state.admin.platform.plansMetaData)
  console.log(plansMetaData, 'plans meta data')
  const planStatus = useAppSelector((state) => state.admin.platform.planStatus)
  const apiError = useAppSelector((state) => state.admin.platform.error)
  const [open, setOpen] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [planId, setPlanId] = useState('')

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'Name',
      key: 'name',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Campaign Name',
      key: 'campaignName',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Type',
      key: 'type',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Frequency',
      key: 'frequency',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Adjustment Count',
      key: 'count',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Price',
      key: 'price',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Add-On Price',
      key: 'membershipPrice',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Active',
      key: 'active',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Default',
      key: 'default',
    },
  ]

  const rows: Array<TableRow> = useMemo(() => {
    return plans.map((p) => {
      return {
        id: p.id || '',
        name: p.name || '-',
        campaignName: p.campaignName || '-',
        type: p.type || '-',
        frequency: p.frequency ? p.frequency : '-',
        count: p.type === 'on-demand' ? (p.count ? p.count.toString() : '-') : p.maxCount ? p.maxCount.toString() : '-',
        price: (p.amount && '$' + (p.amount / 100).toString()) || '-',
        membershipPrice: (p.membershipPrice && '$' + (p.membershipPrice / 100).toString()) || '-',
        active: p.isActive ? 'Yes' : 'No',
        default: p.isDefault ? 'Yes' : 'No',
      }
    })
  }, [plans])

  useEffect(() => {
    dispatch(getPlansAsync({ currentPage: 1, pageSize: 20 }))
  }, [])

  useEffect(() => {
    if (planStatus === StatusEnum.Failed) {
      notifyError('Unable to fetch plans', apiError)
    } else if (planStatus === StatusEnum.Success) {
    }
  }, [planStatus])

  return (
    <div>
      <div style={{ padding: 20, height: plansMetaData?.totalRecordCount > 20 ? '85vh' : '89vh' }}>
        {planStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}

        {planStatus === StatusEnum.Success && (
          <Table
            columns={columns}
            rows={rows}
            buttonText="New Plan"
            totalPageCount={plansMetaData?.totalRecordCount}
            currentPage={plansMetaData?.currentPage}
            onRowClick={(id) => {
              setShowEdit(true)
              setPlanId(id)
            }}
            onButtonClick={() => {
              setOpen(true)
            }}
            onPageChange={(page, pageSize) => {
              dispatch(
                getPlansAsync({
                  pageSize: pageSize,
                  currentPage: page,
                }),
              )
            }}
            initalButtonChildren={'Create Plan'}
            onInitialButtonClicked={() => {
              setOpen(true)
            }}
          ></Table>
        )}
      </div>
      <Drawer
        title="New Plan"
        closable={false}
        extra={
          <Space>
            <div
              className={styles.closeContainer}
              onClick={() => {
                setOpen(false)
              }}
            ></div>
          </Space>
        }
        open={open}
      >
        {open && (
          <NewPlan
            onClose={() => {
              setOpen(false)
            }}
            onCreate={() => {
              setOpen(false)
            }}
          ></NewPlan>
        )}
      </Drawer>
      <Drawer
        title="Edit Plan"
        closable={false}
        extra={
          <Space>
            <div
              className={styles.closeContainer}
              onClick={() => {
                setShowEdit(false)
              }}
            ></div>
          </Space>
        }
        open={showEdit}
      >
        {showEdit && (
          <EditPlan
            planId={planId}
            onClose={() => {
              setShowEdit(false)
            }}
            onUpdate={() => {
              setShowEdit(false)
            }}
          ></EditPlan>
        )}
      </Drawer>
    </div>
  )
}

export default PlanTab

interface AddPlanProps {
  onClose: () => void
  onCreate?: () => void
}
export interface ICreatePlan {
  name: string
  campaign: string | null
  isDefault: boolean
  membershipPrice: string | any
  type: string
  count: string | any
  amount: string | any
  frequency: string | null
  description: string
  isActive: boolean
}
export const NewPlan = ({ onClose, onCreate }: AddPlanProps) => {
  const dispatch = useAppDispatch()
  const { TextArea } = Input
  const postPlanStatus = useAppSelector((state) => state.admin.platform.postPlanStatus)
  const frequencyOptions = [
    { label: 'yearly', value: 'year' },
    { label: 'monthly', value: 'month' },
    { label: 'weekly', value: 'week' },
  ]

  const apiError = useAppSelector((state) => state.admin.platform.error)
  const [selectedType, setSelectedType] = useState('')
  const [form] = Form.useForm()

  const planTypes = [
    {
      label: 'on-demand',
      value: 'on-demand',
    },
    {
      label: 'subscription',
      value: 'subscription',
    },
  ]

  const Default = [
    {
      label: 'yes',
      value: true,
    },
    {
      label: 'no',
      value: false,
    },
  ]

  useEffect(() => {
    if (postPlanStatus === StatusEnum.Failed) {
      notifyError('Unable to create plan', apiError, NotificaitonPlacement.bottomLeft)
    } else if (postPlanStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Plan created successfully')
      onCreate && onCreate()
      dispatch(getPlansAsync({ currentPage: 1, pageSize: 20 }))
    }
    return () => {
      dispatch(resetPostPlanStatus())
    }
  }, [postPlanStatus])

  const onFinish = (values: ICreatePlan) => {
    if (values.count) {
      if (parseInt(values.count) === 0) {
        notifyError('Unable to create plan', 'Adjustment count cannot be zero', NotificaitonPlacement.bottomLeft)
        return
      }
    }
    if (selectedType === 'on-demand') {
      const formattedData = {
        name: values.name,
        campaign: values.campaign ? values.campaign?.trim() : null,
        isDefault: values.isDefault ? true : false,
        membershipPrice: values.membershipPrice && parseInt(values.membershipPrice) * 100,
        type: values.type,
        count: values.count && parseInt(values.count),
        maxCount: null,
        amount: values.amount && parseInt(values.amount) * 100,
        frequency: null,
        description: values.description,
        isActive: true,
      }
      console.log(formattedData, 'values')

      dispatch(postPlanAsync({ data: formattedData }))
    } else if (selectedType === 'subscription') {
      const formattedData = {
        name: values.name,
        campaign: values.campaign ? values.campaign?.trim() : null,
        isDefault: values.isDefault ? true : false,
        membershipPrice: null,
        type: values.type,
        count: null,
        maxCount: parseInt(values.count),
        amount: parseInt(values.amount) * 100,
        frequency: values.frequency,
        description: values.description,
        isActive: true,
      }
      console.log(formattedData, 'values')
      dispatch(postPlanAsync({ data: formattedData }))
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    onClose && onClose()
  }

  return (
    <div className={styles.wrapper}>
      <div style={{ width: '100%' }}>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item<ICreatePlan> label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter Plan Name" />
          </Form.Item>
          <Form.Item<ICreatePlan>
            label="Campaign Name"
            name="campaign"
            rules={[{ required: false, message: 'Please input campaign name!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Campaign Name" />
          </Form.Item>

          <Form.Item<ICreatePlan>
            label="Type"
            name="type"
            rules={[{ required: true, message: 'Please select plan type!' }]}
          >
            <Select
              options={planTypes}
              notFoundContent="No plan available"
              onChange={(value) => {
                setSelectedType(value)
              }}
              suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
              style={{ width: 317, textTransform: 'capitalize' }}
              dropdownStyle={{ textTransform: 'capitalize' }}
              placeholder="Select Plan Type"
            ></Select>
          </Form.Item>

          <Form.Item<ICreatePlan>
            label="Adjustment Count"
            name="count"
            rules={[{ required: true, message: 'Please input count!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Total Adjustments" />
          </Form.Item>

          <Form.Item<ICreatePlan>
            label="Price"
            name="amount"
            rules={[{ required: true, message: 'Please input price!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Price" />
          </Form.Item>

          {selectedType === 'on-demand' && (
            <Form.Item<ICreatePlan>
              label="Add-On Price"
              name="membershipPrice"
              rules={[{ required: true, message: 'Please input Add-On Price!' }]}
            >
              <Input style={{ width: 317 }} placeholder="Enter Add-On Price" />
            </Form.Item>
          )}

          {selectedType === 'subscription' && (
            <Form.Item<ICreatePlan>
              label="Frequency"
              name="frequency"
              rules={[{ required: true, message: 'Please select frequency!' }]}
            >
              <Select
                options={frequencyOptions}
                notFoundContent="No frequency available"
                suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
                style={{ width: 317, textTransform: 'capitalize' }}
                dropdownStyle={{ textTransform: 'capitalize' }}
                placeholder="Select Frequency"
              ></Select>
            </Form.Item>
          )}

          <Form.Item<ICreatePlan>
            label="Default"
            name="isDefault"
            rules={[{ required: false, message: 'Please select a choice!' }]}
          >
            <Select
              options={Default}
              notFoundContent="No options available"
              suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
              style={{ width: 317, textTransform: 'capitalize' }}
              dropdownStyle={{ textTransform: 'capitalize' }}
              placeholder="Select Default"
            ></Select>
          </Form.Item>

          <Form.Item<ICreatePlan>
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please input description!' }]}
          >
            <div style={{ width: 317 }}>
              <TextArea className={styles.textArea} />
            </div>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Submit" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

interface EditPlanProps {
  onClose: () => void
  onUpdate?: () => void
  planId: string
}
export interface IEditPlan {
  name: string
  campaign: string | null
  isDefault: boolean
  membershipPrice: string | any
  type: string
  count: string | any
  amount: string | any
  frequency: string | null
  description: string
  isActive: boolean
}
export const EditPlan = ({ onClose, onUpdate, planId }: EditPlanProps) => {
  const dispatch = useAppDispatch()
  const plans = useAppSelector((state) => state.admin.platform.plans)
  const putPlanStatus = useAppSelector((state) => state.admin.platform.putPlanStatus)
  const apiError = useAppSelector((state) => state.admin.platform.error)
  const [selectedType, setSelectedType] = useState('')
  const [form] = Form.useForm()
  const frequencyOptions = [
    { label: 'yearly', value: 'year' },
    { label: 'monthly', value: 'month' },
    { label: 'weekly', value: 'week' },
  ]

  const planTypes = [
    {
      label: 'on-demand',
      value: 'on-demand',
    },
    {
      label: 'subscription',
      value: 'subscription',
    },
  ]

  const Default = [
    {
      label: 'yes',
      value: true,
    },
    {
      label: 'no',
      value: false,
    },
  ]

  const plan: IEditPlan | any = useMemo(() => {
    let temp = plans?.find((P: any) => P.id === planId)

    if (!temp) {
      return null
    } else {
      return {
        name: temp.name,
        campaign: temp.campaignName ? temp.campaignName?.trim() : null,
        isDefault: temp.isDefault,
        membershipPrice: temp.membershipPrice && temp.membershipPrice / 100,
        type: temp.type,
        maxCount: temp.maxCount,
        count: temp.type === 'on-demand' ? temp.count : temp.maxCount,
        amount: temp.amount / 100,
        frequency: temp.frequency,
        description: temp.description,
        isActive: temp.isActive,
      }
    }
  }, [plans])

  useEffect(() => {
    if (putPlanStatus === StatusEnum.Failed) {
      notifyError('Unable to create plan', apiError, NotificaitonPlacement.bottomLeft)
    } else if (putPlanStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Plan updated successfully')
      onUpdate && onUpdate()
      dispatch(getPlansAsync({ currentPage: 1, pageSize: 20 }))
    }
    return () => {
      dispatch(resetPutPlanStatus())
    }
  }, [putPlanStatus])

  useEffect(() => {
    if (plan) {
      if (plan.type === 'on-demand') {
        setSelectedType('on-demand')
      } else {
        setSelectedType('subscription')
      }
    }
  }, [plan])

  const onFinish = (values: IEditPlan) => {
    if (values.count) {
      if (parseInt(values.count) === 0) {
        notifyError('Unable to create plan', 'Adjustment count cannot be zero', NotificaitonPlacement.bottomLeft)
        return
      }
    }

    if (values.type === 'on-demand') {
      console.log(plan, ' plan')
      console.log(values, ' values')

      if (
        plan.name !== values.name ||
        plan.campaign !== (values.campaign ? values.campaign?.trim() : null) ||
        plan.isDefault !== values.isDefault ||
        plan.membershipPrice !== (isNaN(parseInt(values.membershipPrice)) ? null : parseInt(values.membershipPrice)) ||
        plan.type !== values.type ||
        plan.count !== parseInt(values.count) ||
        plan.amount !== parseInt(values.amount) ||
        plan.description !== values.description ||
        plan.isActive !== values.isActive
      ) {
        const formattedData = {
          name: values.name,
          campaign: values.campaign ? values.campaign?.trim() : null,
          isDefault: values.isDefault ? true : false,
          membershipPrice: values.membershipPrice ? parseInt(values.membershipPrice) * 100 : null,
          type: values.type,
          count: values.count && parseInt(values.count),
          maxCount: null,
          amount: values.amount && parseInt(values.amount) * 100,
          frequency: null,
          description: values.description,
          isActive: values.isActive,
        }

        dispatch(putPlanAsync({ data: formattedData, planId }))
      }
    } else if (values.type === 'subscription') {
      console.log(plan, 'sub plan')
      console.log(values, 'sub values')
      console.log(plan.maxCount !== (isNaN(parseInt(values.count)) ? null : parseInt(values.count)))
      if (
        plan.name !== values.name ||
        plan.campaign !== (values.campaign ? values.campaign?.trim() : null) ||
        plan.isDefault !== values.isDefault ||
        plan.type !== values.type ||
        plan.frequency !== values.frequency ||
        plan.maxCount !== (isNaN(parseInt(values.count)) ? null : parseInt(values.count)) ||
        plan.amount !== parseInt(values.amount) ||
        plan.description !== values.description ||
        plan.isActive !== values.isActive
      ) {
        const formattedData = {
          name: values.name,
          campaign: values.campaign ? values.campaign?.trim() : null,
          isDefault: values.isDefault ? true : false,
          membershipPrice: null,
          type: values.type,
          count: null,
          maxCount: parseInt(values.count),
          amount: parseInt(values.amount) * 100,
          frequency: values.frequency,
          description: values.description,
          isActive: values.isActive,
        }
        dispatch(putPlanAsync({ data: formattedData, planId }))
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    onClose && onClose()
  }

  return (
    <div className={styles.wrapper}>
      <div style={{ width: '100%' }}>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={plan}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={(changedValues, allValues) => {}}
          requiredMark={false}
          autoComplete="off"
        >
          <div className={styles.activeContainer}>
            <div className={styles.isActiveContainer}>
              <span className={styles.isActive}>Active</span>
              <Form.Item<IEditPlan> style={{ width: '100%', marginBottom: 0 }} name="isActive" valuePropName="checked">
                <Switch></Switch>
              </Form.Item>
            </div>
          </div>
          <Form.Item<IEditPlan> label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter Plan Name" />
          </Form.Item>
          <Form.Item<IEditPlan>
            label="Campaign Name"
            name="campaign"
            rules={[{ required: false, message: 'Please input campaign name!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Campaign Name" />
          </Form.Item>
          <Form.Item<IEditPlan>
            label="Type"
            name="type"
            rules={[{ required: true, message: 'Please select plan type!' }]}
          >
            <Select
              options={planTypes}
              notFoundContent="No plan available"
              suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
              style={{ width: 317, textTransform: 'capitalize' }}
              dropdownStyle={{ textTransform: 'capitalize' }}
              onChange={(value) => {
                setSelectedType(value)
              }}
              placeholder="Select Plan Type"
            ></Select>
          </Form.Item>

          <Form.Item<IEditPlan>
            label="Adjustment Count"
            name="count"
            rules={[{ required: true, message: 'Please input count!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Total Adjustments" />
          </Form.Item>

          <Form.Item<IEditPlan>
            label="Price"
            name="amount"
            rules={[{ required: true, message: 'Please input price!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Price" />
          </Form.Item>

          {selectedType === 'on-demand' && (
            <Form.Item<IEditPlan>
              label="Add-On Price"
              name="membershipPrice"
              rules={[{ required: true, message: 'Please input Add-On Price!' }]}
            >
              <Input style={{ width: 317 }} placeholder="Enter Add-On Price" />
            </Form.Item>
          )}

          {(form.getFieldValue('type') || plan.type) === 'subscription' && (
            <Form.Item<IEditPlan>
              label="Frequency"
              name="frequency"
              rules={[{ required: true, message: 'Please select frequency!' }]}
            >
              <Select
                options={frequencyOptions}
                notFoundContent="No frequency available"
                suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
                style={{ width: 317, textTransform: 'capitalize' }}
                dropdownStyle={{ textTransform: 'capitalize' }}
                placeholder="Select Frequency"
              ></Select>
            </Form.Item>
          )}
          <Form.Item<IEditPlan>
            label="Default"
            name="isDefault"
            rules={[{ required: true, message: 'Please select a choice!' }]}
          >
            <Select
              options={Default}
              notFoundContent="No options available"
              suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
              style={{ width: 317, textTransform: 'capitalize' }}
              dropdownStyle={{ textTransform: 'capitalize' }}
              placeholder="Select Default"
            ></Select>
          </Form.Item>
          <div>
            <Form.Item<IEditPlan>
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input description!' }]}
            >
              <Input style={{ width: 317 }}></Input>
            </Form.Item>
          </div>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Save" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
