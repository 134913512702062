import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { format, parse } from 'date-fns'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import styles from './Availabilities.module.scss'
import ErrorPopUp from '../../../../components/ErrorPopup/ErrorPopUp'
import InitialTableComponent from '../../../../components/InitialTableComponent/InitialTableComponent'
import Loading from '../../../../components/Loading/Loading'
import IsActive from '../../../../components/RadioButton'
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton'
import TimeInputDropdown from '../../../../components/TimeInputDropdown/TimeInputDropdown'
import add from '../../../../images/add.svg'
import availabilitySvg from '../../../../images/availability.svg'
import deletes from '../../../../images/delete.svg'
import {
  getAvailabilities,
  postAvailabilities,
  resetGetAvailabilities,
  resetPostAvailabilitiesAuthStatus,
} from '../../../../redux/admin/auth'
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux/store'
import '../../../../components/Button/VariableColors.module.scss'
import { getUserTimezone } from '../../../../utils/DateAndTime'
interface Schedule {
  Mon: Subslot[]
  Tue: Subslot[]
  Wed: Subslot[]
  Thu: Subslot[]
  Fri: Subslot[]
  Sat: Subslot[]
  Sun: Subslot[]
}

interface Subslot {
  id: number
  startTime: {
    label: string
    value: string | null
  }
  startTimeAmPm?: {
    label: string
    value: string | null
  }
  endTime: {
    label: string
    value: string | null
  }
  endTimeAmPm?: {
    label: string
    value: string | null
  }
  day: string
  timezone: string
  isActive: boolean
  // selected?: {
  //   label: string
  //   value: string | null
  // }
}
const Availabilities = () => {
  const { id } = useParams()
  const screenerId: any = id
  const [loadingComponent, setLoadingComponent] = useState(true)
  const [createAvailabilityButtonClicked, setCreateAvailabilityButtonClicked] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  const [schedule, setSchedule] = useState<Schedule>({
    Mon: [
      {
        id: 1,
        startTime: {
          label: '8:00',
          value: '8:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '8:00',
          value: '8:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'mon',
        timezone: 'America/New_York',
        isActive: false,
      },
    ],
    Tue: [
      {
        id: 2,
        startTime: {
          label: '8:00',
          value: '8:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '8:00',
          value: '8:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'tue',
        timezone: 'America/New_York',
        isActive: false,
      },
    ],
    Wed: [
      {
        id: 3,
        startTime: {
          label: '8:00',
          value: '8:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '8:00',
          value: '8:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'wed',
        timezone: 'America/New_York',
        isActive: false,
      },
    ],
    Thu: [
      {
        id: 4,
        startTime: {
          label: '8:00',
          value: '8:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '8:00',
          value: '8:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'thu',
        timezone: 'America/New_York',
        isActive: false,
      },
    ],
    Fri: [
      {
        id: 5,
        startTime: {
          label: '8:00',
          value: '8:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '8:00',
          value: '8:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'fri',
        timezone: 'America/New_York',
        isActive: false,
      },
    ],
    Sat: [
      {
        id: 6,
        startTime: {
          label: '8:00',
          value: '8:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '8:00',
          value: '8:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'sat',
        timezone: 'America/New_York',
        isActive: false,
      },
    ],
    Sun: [
      {
        id: 7,
        startTime: {
          label: '8:00',
          value: '8:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '8:00',
          value: '8:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: 'sun',
        timezone: 'America/New_York',
        isActive: false,
      },
    ],
  })

  const TimeSelector = [
    {
      label: '1:00',
      value: '1:00',
    },
    {
      label: '1:30',
      value: '1:30',
    },
    {
      label: '2:00',
      value: '2:00',
    },
    {
      label: '2:30',
      value: '2:30',
    },
    {
      label: '3:00',
      value: '3:00',
    },
    {
      label: '3:30',
      value: '3:30',
    },
    {
      label: '4:00',
      value: '4:00',
    },
    {
      label: '4:30',
      value: '4:30',
    },
    {
      label: '5:00',
      value: '5:00',
    },
    {
      label: '5:30',
      value: '5:30',
    },
    {
      label: '6:00',
      value: '6:00',
    },
    {
      label: '6:30',
      value: '6:30',
    },
    {
      label: '7:00',
      value: '7:00',
    },
    {
      label: '7:30',
      value: '7:30',
    },
    {
      label: '8:00',
      value: '8:00',
    },
    {
      label: '8:30',
      value: '8:30',
    },
    {
      label: '9:00',
      value: '9:00',
    },
    {
      label: '9:30',
      value: '9:30',
    },
    {
      label: '10:00',
      value: '10:00',
    },
    {
      label: '10:30',
      value: '10:30',
    },
    {
      label: '11:00',
      value: '11:00',
    },
    {
      label: '11:30',
      value: '11:30',
    },
    {
      label: '12:00',
      value: '12:00',
    },
    {
      label: '12:30',
      value: '12:30',
    },
  ]

  const TimeSelectorOptions = TimeSelector?.map((data) => ({ label: data.label, value: data.value })) || []

  const AmPmSelector = [
    {
      label: 'AM',
      value: 'AM',
    },
    {
      label: 'PM',
      value: 'PM',
    },
  ]

  const AmPmOptions = AmPmSelector?.map((data) => ({ label: data.label, value: data.value })) || []
  const dispatch = useAppDispatch()
  const getAvailabilityData: any = useAppSelector((state: RootState) => state.auth.getAvailabilitiesData?.data)
  const getAvailabilityAuthStatus: any = useAppSelector((state: RootState) => state.auth.getAvailabilitiesAuthStatus)
  const getAvailabilityErrorStatus: any = useAppSelector(
    (state: RootState) => state.auth.getAvailabilitiesData?.statusCode,
  )

  const postScreenerAvailabilityStatus: any = useAppSelector((state: RootState) => state.auth.error)
  // const postScreenerAvailabilityData: any = useAppSelector((state: RootState) => state.auth.postAvailabilitiesData)

  const totalCountGetAvailability = getAvailabilityData?.length
  let transformedAvailabilityDataRef = useRef(schedule)

  const userTimezone = useMemo(() => {
    const timezone = 'America/New_York'
    return timezone
  }, [getAvailabilityData])

  const updateScheduleStartTimeAmPm = (day: any, id: any, newStartTimeAmPm: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          startTimeAmPm: newStartTimeAmPm,
        }

        updatedSchedule[day] = daySchedule
      }

      return updatedSchedule
    })
  }

  const updateScheduleEndTimeAmPm = (day: any, id: any, newEndTimeAmPm: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          endTimeAmPm: newEndTimeAmPm,
        }

        updatedSchedule[day] = daySchedule
      }
      return updatedSchedule
    })
  }

  const updateScheduleStartTime = (day: any, id: any, newStartTime: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          startTime: newStartTime,
        }

        updatedSchedule[day] = daySchedule
      }

      return updatedSchedule
    })
  }

  const updateScheduleEndTime = (day: any, id: any, newEndTime: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          endTime: newEndTime,
        }

        updatedSchedule[day] = daySchedule
      }

      return updatedSchedule
    })
  }

  const handleAddRow = (day: any) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const lastId: any = updatedSchedule[day][updatedSchedule[day].length - 1].id
      updatedSchedule[day].push({
        id: lastId + 1,
        startTime: {
          label: '8:00',
          value: '8:00',
        },
        startTimeAmPm: {
          label: 'AM',
          value: 'AM',
        },
        endTime: {
          label: '8:00',
          value: '8:00',
        },
        endTimeAmPm: {
          label: 'PM',
          value: 'PM',
        },
        day: day.toLowerCase(),
        timezone: getAvailabilityData.length !== 0 ? getAvailabilityData[0]?.timezone : userTimezone,
        isActive: false,
      })
      return updatedSchedule
    })
  }

  const handleDeleteRow = (day: any, id: any) => {
    if (id !== 1) {
      const updatedSchedule: any = { ...schedule }
      updatedSchedule[day] = updatedSchedule[day].filter((slot: any) => slot.id !== id)
      setSchedule(updatedSchedule)
    }
  }

  const handleToggleActive = (day: string, id: number) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule: any = { ...prevSchedule }
      const daySchedule: any = [...updatedSchedule[day]]
      const entryIndex = daySchedule.findIndex((entry: any) => entry.id === id)

      if (entryIndex !== -1) {
        daySchedule[entryIndex] = {
          ...daySchedule[entryIndex],
          isActive: !daySchedule[entryIndex].isActive,
        }

        updatedSchedule[day] = daySchedule
      }

      return updatedSchedule
    })
  }

  // TimeConverter

  // timeConverterFunction

  const onCancel = () => {
    const currentTransformedAvailabilityData = transformData()
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      ...currentTransformedAvailabilityData,
    }))
    setDisplayButton(false)
  }

  const handleSubmit = async () => {
    const availabilityData: any = Object.keys(schedule)
      .map((day) => {
        const dayEntries = (schedule as Record<string, any>)[day]

        const convertedDayEntries = dayEntries.map((entry: any) => {
          // const startTime = entry?.startTime?.label + ' ' + entry?.startTimeAmPm?.label
          // const endTime = entry?.endTime?.label + ' ' + entry?.endTimeAmPm?.label
          const parsedStartTime = parse(
            `${entry?.startTime?.label} ${entry?.startTimeAmPm?.label}`,
            'h:mm a',
            new Date(),
          )
          const formattedStartTime = format(parsedStartTime, 'HH:mm:ss')
          const parsedEndTime = parse(`${entry?.endTime?.label} ${entry?.endTimeAmPm?.label}`, 'h:mm a', new Date())
          const formattedEndTime = format(parsedEndTime, 'HH:mm:ss')
          return {
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            day: entry.day,
            timezone: getAvailabilityData.length !== 0 ? getAvailabilityData[0]?.timezone : userTimezone,
            isActive: entry.isActive,
          }
        })

        return convertedDayEntries
      })
      .flat()

    const availData: any = {
      availability: [...availabilityData],
    }
    try {
      await dispatch(postAvailabilities({ data: availData, screenerId: screenerId }))
      setTimeout(() => {
        dispatch(resetPostAvailabilitiesAuthStatus())
      }, 3000)
    } catch {}
    setDisplayButton(false)
  }

  useEffect(() => {
    dispatch(getAvailabilities({ screenerId: screenerId })).finally(() => {
      setLoadingComponent(false)
    })

    return () => {
      dispatch(resetGetAvailabilities())
    }
  }, [])

  const transformData = useCallback(() => {
    const transformedData: Schedule = {
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
      Sun: [],
    }

    getAvailabilityData?.forEach((item: any) => {
      const day = item.day.charAt(0).toUpperCase() + item.day.slice(1)
      transformedData[day as keyof Schedule].push({
        id: transformedData[day as keyof Schedule].length + 1,
        startTime: {
          label: format(new Date(`2024-01-01T${item.startTime}`), 'h:mm'),
          value: format(new Date(`2024-01-01T${item.startTime}`), 'h:mm'),
        },
        startTimeAmPm: {
          label: format(new Date(`2024-01-01T${item.startTime}`), 'a'),
          value: format(new Date(`2024-01-01T${item.startTime}`), 'a'),
        },
        endTime: {
          label: format(new Date(`2024-01-01T${item.endTime}`), 'h:mm'),
          value: format(new Date(`2024-01-01T${item.endTime}`), 'h:mm'),
        },
        endTimeAmPm: {
          label: format(new Date(`2024-01-01T${item.endTime}`), 'a'),
          value: format(new Date(`2024-01-01T${item.endTime}`), 'a'),
        },
        day: item.day,
        timezone: item.timezone,
        isActive: item.isActive,
      })
    })
    console.log(transformedData, 'ss')
    return transformedData
  }, [getAvailabilityData])

  useEffect(() => {
    if (totalCountGetAvailability !== undefined && totalCountGetAvailability !== 0) {
      const transformedData: any = transformData()
      for (const day in transformedData) {
        if (transformedData.hasOwnProperty(day)) {
          if (transformedData[day].length === 0) {
            transformedData[day].push({
              id: 1,
              startTime: {
                label: '8:00',
                value: '8:00',
              },
              startTimeAmPm: {
                label: 'AM',
                value: 'AM',
              },
              endTime: {
                label: '8:00',
                value: '8:00',
              },
              endTimeAmPm: {
                label: 'PM',
                value: 'PM',
              },
              day: day.toLowerCase(),
              timezone: getAvailabilityData[0]?.timezone,
              isActive: false,
            })
          }
        }
      }
      transformedAvailabilityDataRef.current = transformedData

      setSchedule((prevSchedule) => ({
        ...prevSchedule,
        ...transformedData,
      }))
    }
  }, [getAvailabilityData])

  console.log(userTimezone, 'this is the user time zone')

  if (loadingComponent === true) {
    return (
      <div className={styles.loadingGlobal}>
        <Loading style={{ width: '78vw', height: '79vh', background: 'none' }}></Loading>
      </div>
    )
  }

  return (
    <div>
      {getAvailabilityErrorStatus >= 400 && getAvailabilityErrorStatus <= 599 ? (
        <div>
          <ErrorPopUp
            ButtonContent={'Retry'}
            onClick={() => {
              window.location.reload()
            }}
            style={{
              marginTop: '5rem',
              marginRight: '2rem',
              marginLeft: '3.6rem',
            }}
          ></ErrorPopUp>
        </div>
      ) : (
        <div>
          {getAvailabilityData !== undefined && totalCountGetAvailability !== 0 ? (
            <div className={styles.screenerDiv}>
              <div className={styles.screenerAvailability}>
                <div className={styles.screenerAvailabilityHeader}>Availability</div>
                <div className={styles.availabilityOverallContainer}>
                  <div className={styles.availabilityContainer}>
                    {Object.entries(schedule).map(([day, daySchedule], index) => (
                      <div key={index} className={styles.availabilityRow}>
                        <div className={styles.daysDiv}>
                          <h3 className={styles.daysHeader}>{day}</h3>
                        </div>
                        <div className={styles.availabilityDays}>
                          <div>
                            {Array.isArray(daySchedule) &&
                              daySchedule.map((time: any, timeIndex: any) => (
                                <div className={styles.availabilitySet} key={time.id}>
                                  <div className={styles.timeDropdown}>
                                    <div className={styles.timeDropdownContainer}>
                                      <TimeInputDropdown
                                        onChangeSelectTime={(value: any) => {
                                          updateScheduleStartTime(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectTimeValue={time.startTime}
                                        selectTimeOptions={TimeSelectorOptions}
                                        // selectTimeHasValue={errorStartTimeAmPmParent}
                                        onChangeSelectAmPm={(value: any) => {
                                          updateScheduleStartTimeAmPm(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectAmPmValue={time.startTimeAmPm}
                                        selectAmPmOptions={AmPmOptions}
                                        // selectAmPmHasValue={errorStartTimeParent}
                                      ></TimeInputDropdown>
                                      <TimeInputDropdown
                                        onChangeSelectTime={(value: any) => {
                                          updateScheduleEndTime(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectTimeValue={time.endTime}
                                        selectTimeOptions={TimeSelectorOptions}
                                        // selectTimeHasValue={errorEndTimeAmPmParent}
                                        onChangeSelectAmPm={(value: any) => {
                                          updateScheduleEndTimeAmPm(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectAmPmValue={time.endTimeAmPm}
                                        selectAmPmOptions={AmPmOptions}
                                        // selectAmPmHasValue={errorEndTimeParent}
                                      ></TimeInputDropdown>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {timeIndex > 0 ? (
                                        <div
                                          style={{
                                            display: 'flex',
                                            // alignItems: 'center',
                                            // justifyContent: 'center',
                                          }}
                                        >
                                          <div
                                            style={{ cursor: 'pointer', width: '20px' }}
                                            className={styles.deleteBtnParent}
                                          >
                                            <img
                                              style={{ cursor: 'pointer' }}
                                              src={deletes}
                                              alt={'delete'}
                                              onClick={() => {
                                                handleDeleteRow(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                            ></img>
                                          </div>
                                          {/* <span className={styles.isActive}>Is active?</span> */}
                                          <div style={{ marginLeft: '8px', marginTop: '0.6rem' }}>
                                            <IsActive
                                              onClick={() => {
                                                handleToggleActive(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                              isChecked={time.isActive}
                                            ></IsActive>
                                          </div>
                                        </div>
                                      ) : (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <div style={{ cursor: 'pointer', width: '20px' }}>
                                            <img
                                              className={styles.addBtnParent}
                                              src={add}
                                              alt={'add'}
                                              onClick={() => {
                                                handleAddRow(day)
                                                setDisplayButton(true)
                                              }}
                                            ></img>
                                          </div>
                                          {/* <span className={styles.isActive}>Is active?</span> */}
                                          <div style={{ marginLeft: '8px' }} className={styles.radioToggleButtonParent}>
                                            <IsActive
                                              onClick={() => {
                                                handleToggleActive(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                              isChecked={time.isActive}
                                            ></IsActive>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {displayButton && (
                <div className={styles.buttonCtn}>
                  <SecondaryButton
                    children="Cancel"
                    style={{ background: '#272939', marginBottom: '5rem' }}
                    onClick={onCancel}
                  ></SecondaryButton>
                  <SecondaryButton children="Save" onClick={handleSubmit}></SecondaryButton>
                </div>
              )}
              {/* {postScreenerAvailabilityStatus?.code === 'success' && (
                <Toast message="Screener edited successfully" type="success" showToast></Toast>
              )}
              {postScreenerAvailabilityStatus?.statusCode >= 400 &&
                postScreenerAvailabilityStatus?.statusCode <= 599 && (
                  <Toast message="Could not edit Screener" type="error" showToast></Toast>
                )} */}
            </div>
          ) : createAvailabilityButtonClicked && totalCountGetAvailability === 0 ? (
            <div className={styles.screenerDiv}>
              <div className={styles.screenerAvailability}>
                <div className={styles.screenerAvailabilityHeader}>Availability</div>
                <div className={styles.availabilityOverallContainer}>
                  <div className={styles.availabilityContainer}>
                    {Object.entries(schedule).map(([day, daySchedule], index) => (
                      <div key={index} className={styles.availabilityRow}>
                        <div className={styles.daysDiv}>
                          <h3 className={styles.daysHeader}>{day}</h3>
                        </div>
                        <div className={styles.availabilityDays}>
                          <div>
                            {Array.isArray(daySchedule) &&
                              daySchedule.map((time: any, timeIndex: any) => (
                                <div className={styles.availabilitySet} key={time.id}>
                                  <div className={styles.timeDropdown}>
                                    <div className={styles.timeDropdownContainer}>
                                      <TimeInputDropdown
                                        onChangeSelectTime={(value: any) => {
                                          updateScheduleStartTime(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectTimeValue={time.startTime}
                                        selectTimeOptions={TimeSelectorOptions}
                                        // selectTimeHasValue={errorStartTimeAmPmParent}
                                        onChangeSelectAmPm={(value: any) => {
                                          updateScheduleStartTimeAmPm(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectAmPmValue={time.startTimeAmPm}
                                        selectAmPmOptions={AmPmOptions}
                                        // selectAmPmHasValue={errorStartTimeParent}
                                      ></TimeInputDropdown>
                                      <TimeInputDropdown
                                        onChangeSelectTime={(value: any) => {
                                          updateScheduleEndTime(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectTimeValue={time.endTime}
                                        selectTimeOptions={TimeSelectorOptions}
                                        // selectTimeHasValue={errorEndTimeAmPmParent}
                                        onChangeSelectAmPm={(value: any) => {
                                          updateScheduleEndTimeAmPm(day, time.id, value)
                                          setDisplayButton(true)
                                        }}
                                        selectAmPmValue={time.endTimeAmPm}
                                        selectAmPmOptions={AmPmOptions}
                                        // selectAmPmHasValue={errorEndTimeParent}
                                      ></TimeInputDropdown>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {timeIndex > 0 ? (
                                        <div
                                          style={{
                                            display: 'flex',
                                            marginTop: '1rem',
                                            // alignItems: 'center',
                                            // justifyContent: 'center',
                                          }}
                                        >
                                          <div
                                            style={{ cursor: 'pointer', width: '20px' }}
                                            className={styles.deleteBtnParent}
                                          >
                                            <img
                                              style={{ cursor: 'pointer' }}
                                              src={deletes}
                                              alt={'delete'}
                                              onClick={() => {
                                                handleDeleteRow(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                            ></img>
                                          </div>
                                          {/* <span className={styles.isActive}>Is active?</span> */}
                                          <div style={{ marginLeft: '8px', marginTop: '0.6rem' }}>
                                            <IsActive
                                              onClick={() => {
                                                handleToggleActive(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                              isChecked={time.isActive}
                                            ></IsActive>
                                          </div>
                                        </div>
                                      ) : (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <div style={{ cursor: 'pointer', width: '20px' }}>
                                            <img
                                              className={styles.addBtnParent}
                                              src={add}
                                              alt={'add'}
                                              onClick={() => {
                                                handleAddRow(day)
                                                setDisplayButton(true)
                                              }}
                                            ></img>
                                          </div>
                                          {/* <span className={styles.isActive}>Is active?</span> */}
                                          <div style={{ marginLeft: '8px' }} className={styles.radioToggleButtonParent}>
                                            <IsActive
                                              onClick={() => {
                                                handleToggleActive(day, time.id)
                                                setDisplayButton(true)
                                              }}
                                              isChecked={time.isActive}
                                            ></IsActive>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {displayButton && (
                <div className={styles.buttonCtn}>
                  <SecondaryButton
                    children="Cancel"
                    style={{ background: '#272939', marginBottom: '5rem' }}
                    onClick={onCancel}
                  ></SecondaryButton>
                  <SecondaryButton children="Save" onClick={handleSubmit}></SecondaryButton>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.initialComponentContainer}>
              <div className={styles.initialComponentHeading}>Availability</div>
              <div className={styles.initialComponent}>
                <InitialTableComponent
                  ButtonContent={'Create Availability'}
                  TextContent={'availability'}
                  svg={availabilitySvg}
                  styles={{
                    marginTop: '1rem',
                    marginRight: '2rem',
                    marginLeft: '3.6rem',
                  }}
                  onClick={() => {
                    setCreateAvailabilityButtonClicked(true)
                  }}
                ></InitialTableComponent>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Availabilities
