import { useEffect, useMemo, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Input, Layout, Select, Space, Upload } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './Studios.module.scss'
import { AppConstants } from '../../../AppContants'
import Loading from '../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../components/Table'
import Arrow from '../../../images/dropArrow.svg'
import uploadImage from '../../../images/upload.svg'
import { getCitiesAsync } from '../../../redux/admin/platform'
import {
  getStudiosAsync,
  postStudioAsync,
  putStudioAsync,
  resetPostStudioStatus,
  resetPutStudioStatus,
} from '../../../redux/admin/studio'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { StatusEnum } from '../../../redux/type'
import { BaseURL } from '../../../services/Service'
import { NotificaitonPlacement, notifyError, notifySuccess } from '../../../utils/Notification'

export const Studios = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const studios = useAppSelector((state) => state.admin.studio?.studios)
  const studiosMetaData: any = useAppSelector((state) => state.admin.studio?.studiosMetaData)
  const studiosStatus = useAppSelector((state) => state.admin.studio?.studiosStatus)
  const apiError = useAppSelector((state) => state.admin.studio?.error)
  const [open, setOpen] = useState(false)

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'Name',
      key: 'name',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Address',
      key: 'address',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'City',
      key: 'city',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'State',
      key: 'state',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Zip Code',
      key: 'zipcode',
    },
  ]

  const rows: Array<TableRow> = useMemo(() => {
    return studios?.map((d) => {
      return {
        id: d?.id || '',
        name: d?.name || '',
        address: d?.address || '',
        city: d?.city?.name || '',
        state: d?.state.toUpperCase() || '',
        zipcode: d?.zipCode || '',
      }
    })
  }, [studios])

  useEffect(() => {
    dispatch(
      getStudiosAsync({
        currentPage: 1,
        pageSize: 20,
      }),
    )
  }, [])

  useEffect(() => {
    if (studiosStatus === StatusEnum.Failed) {
      notifyError('Unable to fetch studios', apiError)
    } else if (studiosStatus === StatusEnum.Success) {
    }
  }, [studiosStatus])

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <div style={{ padding: 20, height: studiosMetaData?.totalRecordCount > 20 ? '96%' : '99%' }}>
          {studiosStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}

          {studiosStatus === StatusEnum.Success && (
            <Table
              columns={columns}
              rows={rows}
              buttonText="New Studio"
              totalPageCount={studiosMetaData?.totalRecordCount}
              currentPage={studiosMetaData?.currentPage}
              onRowClick={(id) => {
                navigate(`/admin/studios/${id}`)
              }}
              onButtonClick={() => {
                setOpen(true)
              }}
              onPageChange={(page, pageSize) => {
                console.log(page, pageSize)
                dispatch(
                  getStudiosAsync({
                    pageSize: pageSize,
                    currentPage: page,
                  }),
                )
              }}
              initalButtonChildren={'Create Studio'}
              onInitialButtonClicked={() => {
                setOpen(true)
              }}
            ></Table>
          )}
        </div>
        <Drawer
          title="New Studio"
          closable={false}
          extra={
            <Space>
              <div
                className={styles.closeContainer}
                onClick={() => {
                  setOpen(false)
                }}
              ></div>
            </Space>
          }
          open={open}
        >
          {open && (
            <AddStudio
              onClose={() => {
                setOpen(false)
              }}
              onCreate={() => {
                setOpen(false)
              }}
            ></AddStudio>
          )}
        </Drawer>
      </Content>
    </Layout>
  )
}

interface AddStudioProps {
  onClose: () => void
  onCreate?: () => void
}
export interface ICreateStudioDto {
  name: string
  address: string
  state: string
  cityName: string
  zipCode: string
  mapLocation: string
  mapImage: string | File
}
export const AddStudio = ({ onClose, onCreate }: AddStudioProps) => {
  const dispatch = useAppDispatch()
  const cities = useAppSelector((state) => state.admin.platform.cities)
  const cityOptions = cities?.map((c) => ({ label: c?.name, value: c?.name })) || {}
  const studioDtoStatus = useAppSelector((state) => state.admin.studio.putStudioStatus)
  const apiError = useAppSelector((state) => state.admin.studio.error)
  const [form] = Form.useForm()
  // const [fileList, setFileList] = useState([])

  // const handleFileChange = ({ fileList: newFileList }: any) => {
  //   setFileList(newFileList)
  // }

  useEffect(() => {
    dispatch(getCitiesAsync({}))

    return () => {
      dispatch(resetPutStudioStatus())
    }
  }, [])

  const onFinish = async (values: any) => {
    try {
      // Step 1: Creating the clinic
      const clinicResponse: any = await dispatch(postStudioAsync({ data: { ...values, mapImage: null } })).unwrap()
      const clinicId = clinicResponse?.data?.id
      console.log(clinicResponse, 'clinicResponse')

      if (clinicResponse?.code !== 'success') {
        notifyError('Error', 'Unable to create studio.', NotificaitonPlacement.bottomRight)
        return
      }

      // Step 2: Get Signed URL
      const signedUrlResponse = await axios
        .post(
          BaseURL + `/operator/clinic/${clinicId}/signed-url`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(AppConstants.ACCESS_TOKEN)}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })

      let signedUrl
      let clinicPicture

      if (signedUrlResponse?.status === 201 || signedUrlResponse?.status === 200) {
        signedUrl = signedUrlResponse?.data?.signedUrl
        clinicPicture = signedUrlResponse?.data?.clinicPicture
      } else {
        notifyError('Error', 'Studio created, could not upload studio map image.', NotificaitonPlacement.bottomRight)
        console.log(signedUrlResponse, 'Failed to get signed URL')
        return
      }

      // Step 3: Upload the Image
      const mapImage: any = values?.mapImage

      console.log(mapImage, 'map image value')

      const uploadImageRes: any = await uploadImageToSignedUrl(signedUrl, mapImage)
      console.log(uploadImageRes, 'ures')

      if (!uploadImageRes.status) {
        console.log('there is not status property')
        notifyError(
          'Error',
          'Studio created successfully, could not upload studio map image.',
          NotificaitonPlacement.bottomRight,
        )
        return
      } else if (uploadImageRes?.status === 200 || uploadImageRes?.status === 201) {
        console.log('upload image status property is there')
      }

      // Step 4: update clinic image
      const updateStudio: any = await dispatch(
        putStudioAsync({ data: { ...values, mapImage: clinicPicture }, id: clinicId }),
      ).unwrap()

      if (clinicResponse?.code === 'success' && updateStudio?.code === 'success') {
        notifySuccess('Success', 'Studio created successfully.')
        onCreate && onCreate()
        dispatch(
          getStudiosAsync({
            currentPage: 1,
            pageSize: 20,
          }),
        )
      } else {
        notifyError(
          'Error',
          'Studio created successfully, could not upload studio map image url.',
          NotificaitonPlacement.bottomRight,
        )
      }

      console.log(updateStudio, 'updateStudio res')
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const uploadImageToSignedUrl = async (url: string, file: File) => {
    try {
      const response = await axios.put(url, file, {
        headers: {
          'Content-Type': 'image/jpeg',
          'x-goog-acl': 'public-read',
        },
      })
      console.log(response, 'upload image signed url res')
      return response
    } catch (error) {
      console.log('Error Response:', error)
      return error
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    onClose && onClose()
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{}}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item<ICreateStudioDto>
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter studio name" />
          </Form.Item>

          <Form.Item<ICreateStudioDto>
            label="Address"
            name="address"
            rules={[{ required: true, message: 'Please input address!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter the address" />
          </Form.Item>

          <Form.Item style={{ width: 317, marginBottom: 0 }}>
            <Form.Item<ICreateStudioDto>
              name="cityName"
              rules={[{ required: true, message: 'Please input city!' }]}
              style={{ display: 'inline-block', width: '48.4%' }}
            >
              <Select
                notFoundContent="No city available"
                style={{ width: ' 100%', textTransform: 'capitalize' }}
                dropdownStyle={{ textTransform: 'capitalize' }}
                placeholder={'City'}
                options={cityOptions}
                suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
              ></Select>
            </Form.Item>
            <Form.Item<ICreateStudioDto>
              name="state"
              rules={[{ required: true, message: 'Please input state!' }]}
              style={{ display: 'inline-block', width: '48.4%', marginLeft: '1rem' }}
            >
              <Input placeholder="State" className={styles.stateCase} />
            </Form.Item>
          </Form.Item>

          <Form.Item<ICreateStudioDto> name="zipCode" rules={[{ required: true, message: 'Please input zipcode!' }]}>
            <Input style={{ width: 317 }} placeholder="Zip Code" />
          </Form.Item>

          <Form.Item<ICreateStudioDto>
            name="mapLocation"
            label="Address Map Link"
            rules={[{ required: true, message: 'Please enter map link!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Address Map Link" />
          </Form.Item>
          <Form.Item<ICreateStudioDto>
            name="mapImage"
            rules={[{ required: true, message: 'Please choose studio image!' }]}
            style={{ marginTop: '-10px' }}
            valuePropName="mapImage"
            getValueFromEvent={(e) => e?.fileList}
          >
            {/* <Input style={{ width: 317 }} placeholder="Upload Studio Image" /> */}
            <Upload
              accept="image/*"
              className={styles.customUpload}
              multiple={false}
              maxCount={1}
              beforeUpload={() => false}
              onChange={(info) => {
                console.log(info, 'before')
                const file = info.file

                form.setFieldsValue({ mapImage: file })
                console.log(info, 'after')

                // if (fileList.length === 0) {
                //   form.validateFields(['mapImage'])
                // }
              }}
              onRemove={() => {
                form.setFieldsValue({ mapImage: {} })
              }}
            >
              <div
                style={{
                  marginTop: 8,
                  border: '1px solid #2c2d3c',
                  borderRadius: '4px',
                  width: 317,
                  height: 38,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span style={{ marginLeft: '1rem', color: '#858699' }}>Upload Studio Image</span>

                <img src={uploadImage} alt="^" style={{ height: 26, width: 32, marginRight: '10px' }}></img>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Submit" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
