export class AppConstants {
  public static readonly ACCESS_TOKEN = 'adminToken'
  public static readonly CURRENTROLE_TOKEN = 'userCurrentRole'
  public static readonly Questions: any = {
    previous_chiro_care: 'Chiropractic Care',
    pregnancy: 'Pregnancy',
    surgery: 'Surgeries',
    medical_conditions: 'Medical',
    family_health_history: 'Family History',
    musculoskeletal: 'Musculoskeletal',
  }
  public static readonly TimeSelector = [
    {
      label: '1:00',
      value: '1:00',
    },
    {
      label: '1:30',
      value: '1:30',
    },
    {
      label: '2:00',
      value: '2:00',
    },
    {
      label: '2:30',
      value: '2:30',
    },
    {
      label: '3:00',
      value: '3:00',
    },
    {
      label: '3:30',
      value: '3:30',
    },
    {
      label: '4:00',
      value: '4:00',
    },
    {
      label: '4:30',
      value: '4:30',
    },
    {
      label: '5:00',
      value: '5:00',
    },
    {
      label: '5:30',
      value: '5:30',
    },
    {
      label: '6:00',
      value: '6:00',
    },
    {
      label: '6:30',
      value: '6:30',
    },
    {
      label: '7:00',
      value: '7:00',
    },
    {
      label: '7:30',
      value: '7:30',
    },
    {
      label: '8:00',
      value: '8:00',
    },
    {
      label: '8:30',
      value: '8:30',
    },
    {
      label: '9:00',
      value: '9:00',
    },
    {
      label: '9:30',
      value: '9:30',
    },
    {
      label: '10:00',
      value: '10:00',
    },
    {
      label: '10:30',
      value: '10:30',
    },
    {
      label: '11:00',
      value: '11:00',
    },
    {
      label: '11:30',
      value: '11:30',
    },
    {
      label: '12:00',
      value: '12:00',
    },
    {
      label: '12:30',
      value: '12:30',
    },
  ]

  public static readonly TimeSelectorFifteenInterval = [
    { label: '1:00', value: '1:00' },
    { label: '1:15', value: '1:15' },
    { label: '1:30', value: '1:30' },
    { label: '1:45', value: '1:45' },
    { label: '2:00', value: '2:00' },
    { label: '2:15', value: '2:15' },
    { label: '2:30', value: '2:30' },
    { label: '2:45', value: '2:45' },
    { label: '3:00', value: '3:00' },
    { label: '3:15', value: '3:15' },
    { label: '3:30', value: '3:30' },
    { label: '3:45', value: '3:45' },
    { label: '4:00', value: '4:00' },
    { label: '4:15', value: '4:15' },
    { label: '4:30', value: '4:30' },
    { label: '4:45', value: '4:45' },
    { label: '5:00', value: '5:00' },
    { label: '5:15', value: '5:15' },
    { label: '5:30', value: '5:30' },
    { label: '5:45', value: '5:45' },
    { label: '6:00', value: '6:00' },
    { label: '6:15', value: '6:15' },
    { label: '6:30', value: '6:30' },
    { label: '6:45', value: '6:45' },
    { label: '7:00', value: '7:00' },
    { label: '7:15', value: '7:15' },
    { label: '7:30', value: '7:30' },
    { label: '7:45', value: '7:45' },
    { label: '8:00', value: '8:00' },
    { label: '8:15', value: '8:15' },
    { label: '8:30', value: '8:30' },
    { label: '8:45', value: '8:45' },
    { label: '9:00', value: '9:00' },
    { label: '9:15', value: '9:15' },
    { label: '9:30', value: '9:30' },
    { label: '9:45', value: '9:45' },
    { label: '10:00', value: '10:00' },
    { label: '10:15', value: '10:15' },
    { label: '10:30', value: '10:30' },
    { label: '10:45', value: '10:45' },
    { label: '11:00', value: '11:00' },
    { label: '11:15', value: '11:15' },
    { label: '11:30', value: '11:30' },
    { label: '11:45', value: '11:45' },
    { label: '12:00', value: '12:00' },
    { label: '12:15', value: '12:15' },
    { label: '12:30', value: '12:30' },
    { label: '12:45', value: '12:45' },
  ]

  public static readonly Meridiem = [
    {
      label: 'AM',
      value: 'AM',
    },
    {
      label: 'PM',
      value: 'PM',
    },
  ]
}
