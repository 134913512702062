import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, DatePicker, Form, Input, Layout, Select, Switch } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { format, parse } from 'date-fns'
import dayjs, { Dayjs } from 'dayjs'
import { useParams } from 'react-router-dom'
import styles from './ViewDoctor.module.scss'
// import { Availability, Subslot } from '../../../../components/Availability'
import { AvailabilityNew, Subslot } from '../../../../components/AvailabilityNew'
import Loading from '../../../../components/Loading/Loading'
import Arrow from '../../../../images/dropArrow.svg'
import {
  getDoctorAvailabilityByIdAsync,
  getDoctorByIdAsync,
  postDoctorAvailabilityAsync,
  putDoctorAsync,
  resetDoctorAvailability,
  resetDoctorAvailabilityStatus,
  resetPostDoctorAvailabilityStatus,
  resetPutDoctorStatus,
} from '../../../../redux/admin/doctor'
import { getStudiosAsync } from '../../../../redux/admin/studio'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { StatusEnum } from '../../../../redux/type'
import { getUserTimezone } from '../../../../utils/DateAndTime'
import { formatPhoneNumber } from '../../../../utils/FormatPhoneNumber'
import { notifyError, notifySuccess } from '../../../../utils/Notification'

export interface IEditDoctorDto {
  firstname: string
  lastname: string
  // dob: string
  phone: string
  email: string
  clinicId: string
  isActive: boolean
}
const DEFAULT_SCHEDULE = [
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'mon',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'tue',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'wed',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'thu',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'fri',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'sat',
    timezone: 'America/New_York',
    isActive: false,
  },
  {
    id: 1,
    startTime: {
      label: '8:00',
      value: '8:00',
    },
    startMeridiem: {
      label: 'AM',
      value: 'AM',
    },
    endTime: {
      label: '8:00',
      value: '8:00',
    },
    endMeridiem: {
      label: 'PM',
      value: 'PM',
    },
    day: 'sun',
    timezone: 'America/New_York',
    isActive: false,
  },
]
const ViewDoctor = () => {
  const dispatch = useAppDispatch()
  const { id }: any = useParams()
  const doctors = useAppSelector((state) => state.admin.doctor.doctors)
  const doctorStatus = useAppSelector((state) => state.admin.doctor.putDoctorStatus)
  const apiError = useAppSelector((state) => state.admin.doctor.error)
  const studios = useAppSelector((state) => state.admin.studio.studios)
  const studioOptions = studios?.map((s) => ({ label: s?.name, value: s?.id })) || {}
  const [form] = Form.useForm()
  const [showButtons, setShowButtons] = useState(false)
  const doctorAvailability = useAppSelector((state) => state.admin.doctor.doctorAvailability)
  // const availability = useAppSelector((state) => state.admin.doctor.postDoctorAvailability)
  const availabilityStatus = useAppSelector((state) => state.admin.doctor.postDoctorAvailabilityStatus)
  const doctorAvailabilityStatus = useAppSelector((state) => state.admin.doctor.doctorAvailabilityStatus)
  const [showAvailabilitySave, setShowAvailabilitySave] = useState(false)
  const [schedule, setSchedule] = useState<Array<Subslot>>([])
  const [existingSchedule, setExistingSchedule] = useState<Array<Subslot>>([])
  const [showAll, setShowAll] = useState(false)
  const [isCancel, setIsCancel] = useState(false)

  const userTimezone = useMemo(() => {
    const timezone = 'America/New_York'
    return timezone
  }, [doctorAvailability])

  if (true) {
    console.log('tur')
  }
  const doctor: any = useMemo(() => {
    let temp = doctors?.find((D: any) => D.id === id)
    if (!temp) {
      return null
    } else {
      return {
        firstname: temp.user?.firstname || '',
        lastname: temp.user?.lastname || '',
        email: temp.user?.email || '',
        phone: formatPhoneNumber(temp.user?.phone) || '',
        // dob: temp.user?.dob === null ? dayjs('01-01-1990') : dayjs(temp?.user?.dob),
        clinicId: temp.clinic?.id || '',
        isActive: temp.isActive,
      }
    }
  }, [doctors])

  useEffect(() => {
    dispatch(getStudiosAsync({}))
  }, [])

  useEffect(() => {
    dispatch(getDoctorAvailabilityByIdAsync({ id: id }))

    return () => {
      dispatch(resetDoctorAvailability())
      dispatch(resetDoctorAvailabilityStatus())
    }
  }, [isCancel])

  // useEffect(() => {
  //   dispatch(getDoctorAvailabilityByIdAsync({ id: id }))
  // }, [])

  useEffect(() => {
    const foundDoctor = doctors?.find((D: any) => D.id === id)
    if (!foundDoctor) {
      dispatch(getDoctorByIdAsync({ doctorId: id }))
    }
  }, [doctors])

  useEffect(() => {
    if (doctorAvailabilityStatus === StatusEnum.Success) {
      if (doctorAvailability.length !== 0) {
        const updatedSchedule = assignDayIds(doctorAvailability)
        setSchedule(updatedSchedule)
        setExistingSchedule(updatedSchedule)
      }
    }
  }, [doctorAvailability])

  useEffect(() => {
    if (doctorStatus === StatusEnum.Failed) {
      notifyError('Unable to update doctor', apiError)
    } else if (doctorStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Doctor updated successfully')
      setShowButtons(false)
    }
    return () => {
      dispatch(resetPutDoctorStatus())
    }
  }, [doctorStatus])

  useEffect(() => {
    if (availabilityStatus === StatusEnum.Failed) {
      notifyError('Unable to update doctor availability', apiError)
    } else if (availabilityStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Doctor availability updated successfully')
    }
    return () => {
      dispatch(resetPostDoctorAvailabilityStatus())
    }
  }, [availabilityStatus])

  const onFinish = (values: IEditDoctorDto) => {
    values.phone = `+1${values.phone.replace(/[^0-9]/g, '')}`
    dispatch(putDoctorAsync({ data: values, id: id }))
  }

  const shouldShowSaveButton = useCallback(
    (newValues: any) => {
      // compare doctor with new values
      if (
        doctor.firstname === newValues.firstname &&
        doctor.lastname === newValues.lastname &&
        doctor.phone === newValues.phone &&
        doctor.email === newValues.email &&
        // dayjs(doctor.dob, 'MM/DD/YYYY').format('MM/DD/YYYY') === dayjs(newValues.dob)?.format('MM/DD/YYYY') &&
        doctor.isActive === newValues.isActive &&
        doctor.clinicId === newValues.clinicId
      ) {
        setShowButtons(false)
      } else {
        setShowButtons(true)
      }
    },
    [doctor, setShowButtons],
  )

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onCancel = () => {
    form.resetFields()
    setShowButtons(false)
  }
  const disabledDate = (current: any) => {
    return current && dayjs(current).isAfter(dayjs().endOf('day'))
  }

  const handleAvailabilitySubmit = () => {
    const availability: any = schedule.map((s) => {
      const parsedStartTime = parse(`${s?.startTime.label} ${s?.startMeridiem.label}`, 'h:mm a', new Date())
      const formattedStartTime = format(parsedStartTime, 'HH:mm:ss')
      const parsedEndTime = parse(`${s?.endTime.label} ${s?.endMeridiem.label}`, 'h:mm a', new Date())
      const formattedEndTime = format(parsedEndTime, 'HH:mm:ss')
      return {
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        day: s.day,
        timezone: doctorAvailability.length !== 0 ? doctorAvailability[0].timezone : userTimezone,
        isActive: s.isActive,
      }
    })

    const availData: any = {
      availability: [...availability],
    }

    try {
      dispatch(postDoctorAvailabilityAsync({ data: availData, id: id }))
    } catch {}

    setShowAvailabilitySave(false)
    setShowAll(false)
  }

  const handleAvailabilityCancel = () => {
    setIsCancel(!isCancel)
    setSchedule(existingSchedule)
    setShowAll(false)
    setShowAvailabilitySave(false)
  }

  const assignDayIds = (schedule: any) => {
    const idCount: any = {}
    const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    const scheduledDays = schedule.map((item: any) => item.day)
    const missingDays = daysOfWeek.filter((day) => !scheduledDays.includes(day))

    const defaultObject = {
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '20:00:00',
      endMeridiem: 'PM',
      isActive: false,
    }

    const result = [...schedule]

    for (const day of missingDays) {
      result.push({ ...defaultObject, day, id: 1 })
    }

    return result.map((item: any) => {
      const { day, startTime, endTime } = item
      if (!idCount[day]) {
        idCount[day] = 1
      }
      const id = idCount[day]
      idCount[day]++

      if (startTime === '8:00 AM' && endTime === '8:00 PM') {
        return {
          startTime: {
            label: '8:00',
            value: '8:00',
          },
          startMeridiem: {
            label: 'AM',
            value: 'AM',
          },
          endTime: {
            label: '8:00',
            value: '8:00',
          },
          endMeridiem: {
            label: 'PM',
            value: 'PM',
          },
          id,
          day: item.day,
          isActive: item.isActive,
          timezone: item.timezone,
        }
      }

      return {
        startTime: {
          label: format(new Date(`2024-01-01T${startTime}`), 'h:mm'),
          value: format(new Date(`2024-01-01T${startTime}`), 'h:mm'),
        },
        startMeridiem: {
          label: format(new Date(`2024-01-01T${startTime}`), 'a'),
          value: format(new Date(`2024-01-01T${startTime}`), 'a'),
        },
        endTime: {
          label: format(new Date(`2024-01-01T${endTime}`), 'h:mm'),
          value: format(new Date(`2024-01-01T${endTime}`), 'h:mm'),
        },
        endMeridiem: {
          label: format(new Date(`2024-01-01T${endTime}`), 'a'),
          value: format(new Date(`2024-01-01T${endTime}`), 'a'),
        },
        id,
        day: item.day,
        isActive: item.isActive,
        timezone: item.timezone,
      }
    })
  }

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content style={{ padding: '2rem' }} className={styles.content}>
        <div className={styles.editDoctorHeader}>Edit Doctor</div>
        <div className={styles.editContainer}>
          {doctor && (
            <Form
              labelWrap={true}
              form={form}
              name="basic"
              layout="vertical"
              style={{}}
              initialValues={doctor}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              requiredMark={false}
              autoComplete="off"
              onValuesChange={(c: any, a: any) => {
                shouldShowSaveButton(a)
              }}
            >
              <div className={styles.activeContainer}>
                <div className={styles.isActiveContainer}>
                  <span className={styles.isActive}>Active</span>
                  <Form.Item<IEditDoctorDto>
                    style={{ width: '100%', marginBottom: 0 }}
                    name="isActive"
                    valuePropName="checked"
                  >
                    <Switch></Switch>
                  </Form.Item>
                </div>
              </div>

              <div className={styles.rowOne}>
                <Form.Item<IEditDoctorDto>
                  label="First Name"
                  name="firstname"
                  rules={[{ required: true, message: 'Please input first name!' }]}
                  style={{ width: '100%', textTransform: 'capitalize' }}
                >
                  <Input placeholder="Enter First Name" style={{ textTransform: 'capitalize' }} />
                </Form.Item>

                <Form.Item<IEditDoctorDto>
                  label="Last Name"
                  name="lastname"
                  rules={[{ required: true, message: 'Please input last name!' }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder="Enter Last Name" style={{ textTransform: 'capitalize' }} />
                </Form.Item>
              </div>
              <div className={styles.rowTwo}>
                {/* <Form.Item<IEditDoctorDto>
                  label="Date of Birth"
                  name="dob"
                  rules={[{ required: true, message: 'Please input dob!' }]}
                  style={{ width: '100%' }}
                >
                  <DatePicker
                    format={{
                      format: 'MM-DD-YYYY',
                      type: 'mask',
                    }}
                    placeholder="MM/DD/YYYY"
                    style={{ height: '3.8rem', width: '100%' }}
                    suffixIcon={false}
                    disabledDate={disabledDate}
                  ></DatePicker>
                </Form.Item> */}

                <Form.Item<IEditDoctorDto>
                  label="Phone"
                  name="phone"
                  rules={[{ required: true, message: 'Please input phone!', min: 12 }]}
                  style={{ width: '100%' }}
                >
                  <Input
                    placeholder="Enter Phone Number"
                    disabled
                    onChange={(e: any) => {
                      const formattedValue = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 10)
                        .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_: any, first: any, second: any, third: any) => {
                          let result = first
                          if (second) result += '-' + second
                          if (third) result += '-' + third
                          return result
                        })
                      form.setFieldValue('phone', formattedValue)
                    }}
                  />
                </Form.Item>
                <Form.Item<IEditDoctorDto>
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input email!' }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder="Enter Email Address" disabled />
                </Form.Item>
              </div>
              <div className={styles.rowThree}>
                <Form.Item<IEditDoctorDto>
                  label="Studio"
                  name="clinicId"
                  rules={[{ required: true, message: 'Please input studio!' }]}
                  style={{ width: '100%' }}
                >
                  <Select
                    notFoundContent="No studio available"
                    disabled
                    placeholder={'Select Studio'}
                    options={studioOptions}
                    suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
                    style={{ textTransform: 'capitalize', width: '100%' }}
                    dropdownStyle={{ textTransform: 'capitalize' }}
                  ></Select>
                </Form.Item>
                <div className={styles.buttonContainer}>
                  {showButtons && (
                    <Form.Item>
                      <div className={styles.footer}>
                        <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
                        <Button className={styles.button} children="Save" htmlType="submit" type="primary"></Button>
                      </div>
                    </Form.Item>
                  )}
                </div>
              </div>
            </Form>
          )}
        </div>
        <div className={styles.availAndEditSchedule}>
          <div className={styles.availabilityHeader}>Availability</div>
        </div>
        {doctorAvailabilityStatus === StatusEnum.Pending && <Loading style={{ width: '100%' }}></Loading>}
        {doctorAvailabilityStatus === StatusEnum.Success && (
          <div className={styles.availability}>
            <AvailabilityNew
              onCreate={() => {
                setSchedule(DEFAULT_SCHEDULE)
                setShowAll(true)
                setShowAvailabilitySave(true)
              }}
              showActive={showAvailabilitySave}
              schedules={schedule}
              onScheduleChange={(schedule) => setSchedule(schedule)}
              onScheduleMeridiemChange={(schedule) => setSchedule(schedule)}
              showAvailabilityButton={() => {
                setShowAvailabilitySave(true)
              }}
              hideAvailabilityButton={() => {
                setShowAvailabilitySave(false)
              }}
              setShowAll={() => {
                setShowAll(true)
              }}
              existingSchedule={existingSchedule}
              timezone={doctorAvailability.length !== 0 ? doctorAvailability[0].timezone : userTimezone}
              showAll={showAll}
            ></AvailabilityNew>
            <div className={styles.availabilityBtnContainer}>
              {showAvailabilitySave && (
                <Form.Item>
                  <div className={styles.footer}>
                    <Button
                      className={styles.button}
                      children="Cancel"
                      type="default"
                      onClick={handleAvailabilityCancel}
                    ></Button>
                    <Button
                      className={styles.button}
                      children="Save"
                      onClick={handleAvailabilitySubmit}
                      type="primary"
                    ></Button>
                  </div>
                </Form.Item>
              )}
            </div>
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default ViewDoctor
